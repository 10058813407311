import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Link,
} from '@mui/material';
import Breadcrumbs from 'Components/Breadcrumbs';
import MonthPicker from 'Components/MonthPicker';
import useResize from 'Hooks/useResize';
import React, { Fragment, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { timetableState } from 'Recoil/Atoms/Timetable';
import userState from 'Recoil/Atoms/User';
import { TimetableEffect } from 'Recoil/Effects/TimetableEffect';
import MONTHS from 'Dictionary/months';

export default function Timetable() {
  const { state } = useLocation();
  let externalUserID;
  let externalName;
  if (state) {
    externalUserID = state.externalUserID;
    externalName = state.externalName;
  }
  const [month, setMonth] = useState(new Date().getMonth() + 1);
  const [year, setYear] = useState(new Date().getFullYear());
  const user = useRecoilValue(userState);
  const timetable = Object.entries(useRecoilValue(timetableState));
  const { isDesktop } = useResize();

  return (
    <>
      <TimetableEffect
        year={year}
        month={month}
        id={externalUserID || user.id}
      />
      <Breadcrumbs
        lastName={`${MONTHS[month || new Date().getMonth() + 1]} - ${
          externalName || user.full_name
        }`}
      />
      <Typography variant="h4" sx={{ margin: '25px 0' }}>
        Расписание {externalName || user.full_name}
      </Typography>
      <MonthPicker
        month={month}
        monthChange={setMonth}
        year={year}
        yearChange={setYear}
      />
      {timetable.map((day) => (
        <Fragment key={day[0]}>
          <Typography
            sx={{ padding: '8px 16px', fontWeight: '600' }}
            variant="body2"
          >
            {day[0]}
          </Typography>
          <TableContainer component={Paper}>
            <Table sx={{ maxWidth: '100%' }} aria-label="budget-report table">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ width: '100px' }}>Время</TableCell>
                  <TableCell size={isDesktop ? 'medium' : 'small'}>
                    Задача
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {day[1].map((task) => (
                  <TableRow key={task.id}>
                    <TableCell sx={{ width: '100px' }}>
                      {task.value.replace('.', ':')}ч
                    </TableCell>
                    <TableCell size={isDesktop ? 'medium' : 'small'}>
                      {task.parent_url ? (
                        <Link href={task.parent_url}>{task.parent_name}</Link>
                      ) : (
                        task.parent_name
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Typography
            sx={{ marginBottom: '22px', padding: '8px 16px' }}
            variant="body2"
          >
            Всего{' '}
            {day[1]
              .reduce((acc, item) => acc + Number(item.value), 0)
              .toFixed(2)
              .toString()
              .replace('.', ':')}
            ч
          </Typography>
        </Fragment>
      ))}
    </>
  );
}
