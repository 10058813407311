import React, { useEffect, useState } from 'react';
import { Box, Stack, Drawer, Button, List, Divider } from '@mui/material';
import useResize from 'Hooks/useResize';
import Hamburger from 'hamburger-react';
import ROUTES from 'Dictionary/routes';
import userState from 'Recoil/Atoms/User';
import { useRecoilValue } from 'recoil';
import useUserActions from 'Hooks/useUserActions';
import MenuButton from './MenuButton';

export default function Header() {
  const { logged, role } = useRecoilValue(userState);
  const { isPhone } = useResize();
  const { signOut } = useUserActions();
  const [isMenuOpen, setMenuOpen] = useState(false);

  interface ILink {
    link: string;
    name: string;
  }
  interface ILinks {
    [x: string]: ILink[];
  }

  const navigation = {
    user: [
      { link: ROUTES.reports(), name: 'Отчёты' },
      { link: ROUTES.estimate(), name: 'Изменения оценки' },
      { link: ROUTES.myTimetable(), name: 'Моё расписание' },
    ],
    manager: [
      { link: ROUTES.reports(), name: 'Отчёты' },
      { link: ROUTES.estimate(), name: 'Изменения оценки' },
      { link: ROUTES.projects(), name: 'Проекты' },
      { link: ROUTES.users(), name: 'Пользователи' },
      { link: ROUTES.budgetReport(), name: 'Отчёт по бюджету' },
    ],
    administrator: [
      { link: ROUTES.reports(), name: 'Отчёты' },
      { link: ROUTES.estimate(), name: 'Изменения оценки' },
      { link: ROUTES.projects(), name: 'Проекты' },
      { link: ROUTES.users(), name: 'Пользователи' },
      { link: ROUTES.budgetReport(), name: 'Отчёт по бюджету' },
      { link: ROUTES.units(), name: 'Подразделения' },
    ],
  } as ILinks;

  const sectionList =
    role &&
    navigation[role].map((section) => (
      <MenuButton
        key={section.name}
        href={section.link}
        text={section.name}
        fontSize="12px"
        isMobile={isPhone}
      />
    ));

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        padding: isPhone ? '0: 10px' : '0 50px',
        justifyContent: 'space-between',
        height: 50,
        minHeigh: 50,
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        backgroundColor: '#fff',
        border: 'solid #d3e0e9',
        borderWidth: '0 0 1px 0',
        zIndex: 1300,
      }}
    >
      <Stack
        direction="row"
        spacing={2}
        sx={(theme) => ({
          [theme.breakpoints.down('sm')]: {
            width: '100%',
            justifyContent: 'space-between',
          },
        })}
      >
        <MenuButton
          href={ROUTES.reports()}
          text="Project Gain"
          fontSize="18px"
        />
        {isPhone ? (
          <Button onClick={() => setMenuOpen(!isMenuOpen)}>
            <Hamburger
              toggled={isMenuOpen}
              toggle={setMenuOpen}
              size={25}
              color="#888"
            />
            <Drawer
              anchor="top"
              open={isMenuOpen}
              onClose={() => setMenuOpen(false)}
              sx={{
                width: '50px',
                [`& .MuiPaper-root`]: {
                  marginTop: '50px',
                  boxShadow:
                    '0px 8px 10px -5px rgb(0 0 0 / 20%), 0px 16px 24px 2px rgb(0 0 0 / 14%)',
                },
                [`& .MuiBackdrop-root`]: { marginTop: '50px' },
              }}
            >
              <Divider />
              <List>
                {logged ? (
                  <>
                    <MenuButton
                      href={ROUTES.account()}
                      text="Личный кабинет"
                      fontSize="14px"
                      isMobile
                    />
                    <MenuButton
                      onClick={() => signOut()}
                      text="Выйти"
                      fontSize="14px"
                      isMobile
                    />
                  </>
                ) : (
                  <MenuButton
                    href={ROUTES.auth()}
                    text="Войти"
                    fontSize="14px"
                    isMobile
                  />
                )}
                <Divider />
                {sectionList}
              </List>
            </Drawer>
          </Button>
        ) : (
          sectionList
        )}
      </Stack>
      {!isPhone && (
        <Stack direction="row" justifyContent="flex-end" spacing={2}>
          {logged ? (
            <>
              <MenuButton
                href={ROUTES.account()}
                text="Личный кабинет"
                fontSize="14px"
              />
              <MenuButton
                onClick={() => signOut()}
                text="Выйти"
                fontSize="14px"
              />
            </>
          ) : (
            <MenuButton href={ROUTES.auth()} text="Войти" fontSize="14px" />
          )}
        </Stack>
      )}
    </Box>
  );
}
