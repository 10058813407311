import React, { useEffect } from 'react';
import {
  Navigate,
  Route,
  Routes,
  Outlet,
  useNavigate,
  useLocation,
} from 'react-router-dom';
import SignUp from 'Pages/Auth';
import Reports from 'Pages/Reports';
import Projects from 'Pages/Projects';
import Project from 'Pages/Projects/Project';
import userState from 'Recoil/Atoms/User';
import { useRecoilValue } from 'recoil';
import Units from 'Pages/Units';
import PersonalAccount from 'Pages/PersonalAccount';
import Users from 'Pages/Users';
import ROUTES from 'Dictionary/routes';
import UserReport from 'Pages/Reports/UserReport';
import EstimateReport from 'Pages/Reports/EstimateReport';
import FailedReport from 'Pages/Reports/FailedReport';
import BudgetReport from 'Pages/BudgetReport';
import Holidays from 'Pages/Reports/Holidays';
import Timetable from 'Pages/Timetable';
import PersonalAccountUpdate from 'Pages/PersonalAccount/PersonalForm';
import Estimate from 'Pages/Estimate';
import secureLocalStorage from 'react-secure-storage';
import PublicRoute from './PublicRouteWrapper';
import PrivateRoute from './PrivateRouteWrapper';
import AdministratorRoute from './AdministratorRouteWrapper';
import ManagerRoute from './ManagerRouteWrapper';

export default function Main() {
  const location = useLocation();
  const navigate = useNavigate();
  const user = useRecoilValue(userState);
  const role = secureLocalStorage.getItem('gain_user_token');

  useEffect(() => {
    if (role !== 'user' && location.pathname === ROUTES.myTimetable()) {
      navigate(ROUTES.reports());
    }
  }, [location]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  // так как механика логина отрабатывает через бэк - пока оставляем все роуты доступными, в конце запротектим уже

  return (
    <main>
      <Routes>
        <Route
          path={ROUTES.reports()}
          element={
            <PrivateRoute>
              <Reports />
            </PrivateRoute>
          }
        />
        <Route
          path={ROUTES.account()}
          element={
            <PrivateRoute>
              <PersonalAccount />
            </PrivateRoute>
          }
        />
        <Route
          path={ROUTES.estimate()}
          element={
            <PrivateRoute>
              <Estimate />
            </PrivateRoute>
          }
        />
        <Route
          path={ROUTES.accountUpdate()}
          element={
            <PrivateRoute>
              <PersonalAccountUpdate />
            </PrivateRoute>
          }
        />
        <Route
          path={ROUTES.report()}
          element={
            <PrivateRoute>
              <UserReport />
            </PrivateRoute>
          }
        />
        <Route
          path={ROUTES.estimateReport()}
          element={
            <PrivateRoute>
              <EstimateReport />
            </PrivateRoute>
          }
        />
        <Route
          path={ROUTES.failedReport()}
          element={
            <PrivateRoute>
              <FailedReport />
            </PrivateRoute>
          }
        />
        <Route
          path={ROUTES.budgetReport()}
          element={
            <PrivateRoute>
              <BudgetReport />
            </PrivateRoute>
          }
        />
        <Route
          path={ROUTES.myTimetable()}
          element={
            <PrivateRoute>
              <Timetable />
            </PrivateRoute>
          }
        />
        <Route
          path={ROUTES.users()}
          element={
            <PrivateRoute>
              <Users />
            </PrivateRoute>
          }
        />
        <Route
          path={ROUTES.holidays()}
          element={
            <PrivateRoute>
              <ManagerRoute role={(role as string) || 'user'}>
                <Holidays />
              </ManagerRoute>
            </PrivateRoute>
          }
        />
        <Route
          path={ROUTES.timetable()}
          element={
            <PrivateRoute>
              <AdministratorRoute role={(role as string) || 'user'}>
                <Timetable />
              </AdministratorRoute>
            </PrivateRoute>
          }
        />
        <Route
          path={ROUTES.projects()}
          element={
            <PrivateRoute>
              <ManagerRoute role={(role as string) || 'user'}>
                <Projects />
              </ManagerRoute>
            </PrivateRoute>
          }
        />
        <Route
          path={ROUTES.project()}
          element={
            <PrivateRoute>
              <ManagerRoute role={(role as string) || 'user'}>
                <Project />
              </ManagerRoute>
            </PrivateRoute>
          }
        />
        <Route
          path={ROUTES.units()}
          element={
            <PrivateRoute>
              <AdministratorRoute role={(role as string) || 'user'}>
                <Units />
              </AdministratorRoute>
            </PrivateRoute>
          }
        />
        <Route path="/" element={<Navigate to={ROUTES.reports()} replace />} />

        <Route
          path={ROUTES.auth()}
          element={
            <PublicRoute>
              <SignUp />
            </PublicRoute>
          }
        />
        <Route path="*" element={<Navigate to={ROUTES.reports()} replace />} />
      </Routes>
    </main>
  );
}
