import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import Button from '@mui/material/Button';
import TableRow from '@mui/material/TableRow';
import { Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import useResize from 'Hooks/useResize';
import { reportState } from 'Recoil/Atoms/Report';
import { ReportEffect } from 'Recoil/Effects/ReportEffect';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useLocation } from 'react-router-dom';
import MONTHS from 'Dictionary/months';
import Breadcrumbs from 'Components/Breadcrumbs';
import Api from 'Api';
import React, { useState, useEffect, useMemo } from 'react';
import useAPIError from 'Hooks/useAPIError';
import LoadingLayout from 'Components/Layout';

export default function UserReport() {
  const { state } = useLocation();
  const { id, month, year, name } = state;
  const report = useRecoilValue(reportState);
  const setReport = useSetRecoilState(reportState);
  const { isDesktop } = useResize();
  const { handleAPIError } = useAPIError();
  const [loading, setLoading] = useState<boolean>(false);

  const styles = useMemo(() => {
    return {
      tableCell: { width: isDesktop ? 50 : 25, padding: isDesktop ? 16 : 10 },
      tableCellWide: {
        width: isDesktop ? 250 : 100,
        padding: isDesktop ? 16 : 10,
      },
      tableCellMedium: {
        width: isDesktop ? 150 : 50,
        padding: isDesktop ? 16 : 10,
      },
    };
  }, [isDesktop]);

  useEffect(() => {
    async function fetchReports() {
      try {
        const { results } = await Api.get(Api.routes.api.report(), {
          account_id: id,
          report_month: month.toString() || new Date().getMonth() + 1,
          report_year: year.toString() || new Date().getFullYear(),
        });
        setReport(results);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        handleAPIError(error);
      }
    }

    fetchReports();
  }, []);

  const refreshCollab = () => {
    setLoading(true);
    async function refreshReports() {
      try {
        await Api.getRefresh(
          Api.routes.importApi.report(year.toString(), month.toString()),
          {}
        );
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        handleAPIError(error);
        setLoading(false);
      }
    }
    async function fetchReports() {
      try {
        const { results } = await Api.get(Api.routes.api.report(), {
          account_id: id,
          report_month: month.toString() || new Date().getMonth() + 1,
          report_year: year.toString() || new Date().getFullYear(),
        });
        setReport(results);
        setLoading(false);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        handleAPIError(error);
        setLoading(false);
      }
    }

    refreshReports();
    fetchReports();
  };

  return (
    <>
      <LoadingLayout isLoading={loading} />
      <ReportEffect month={month} year={year} id={id} />
      <Breadcrumbs
        lastName={`${MONTHS[month || new Date().getMonth() + 1]} - ${name}`}
      />
      <Typography variant="h4" sx={{ margin: '25px 0' }}>
        {MONTHS[month || new Date().getMonth() + 1]} - {name} - Все задачи
      </Typography>
      <Button
        variant="outlined"
        size="small"
        onClick={refreshCollab}
        sx={{ margin: '20px 0' }}
      >
        Обновить данные с Active Collab
      </Button>
      <TableContainer component={Paper}>
        <Table sx={{ maxWidth: '100%' }} aria-label="projects table">
          <TableHead>
            <TableRow>
              <TableCell size={isDesktop ? 'medium' : 'small'}>
                Задача (списанные/оцененные часы)
              </TableCell>
              <TableCell size={isDesktop ? 'medium' : 'small'} align="right">
                Комментарий к списанию времени
              </TableCell>
              <TableCell size={isDesktop ? 'medium' : 'small'} align="right">
                Количество часов
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {report.map((row) => (
              <TableRow
                key={row.id}
                sx={{
                  textDecoration: 'none',
                }}
              >
                <TableCell
                  style={styles.tableCellMedium}
                  component="th"
                  scope="row"
                >
                  {row.assignment?.name || ''}
                </TableCell>
                <TableCell style={styles.tableCell} align="right">
                  {row.description || ''}
                </TableCell>
                <TableCell style={styles.tableCell} align="right">
                  {row.value || ''}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Typography sx={{ marginTop: '50px' }}>Всего {report.length}</Typography>
    </>
  );
}
