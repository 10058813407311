import React, { useState, useMemo } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import Button from '@mui/material/Button';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import useResize from 'Hooks/useResize';
import { assignmentState } from 'Recoil/Atoms/Assignment';
import { AssignmentEffect } from 'Recoil/Effects/AssignmentEffect';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useLocation } from 'react-router-dom';
import { Typography } from '@mui/material';
import MONTHS from 'Dictionary/months';
import Breadcrumbs from 'Components/Breadcrumbs';
import Api from 'Api';
import useAPIError from 'Hooks/useAPIError';
import { IReport } from 'Recoil/Atoms/Report';
import LoadingLayout from 'Components/Layout';

export default function FailedReport() {
  const { state } = useLocation();
  const { id, month, year, name } = state;
  const report = useRecoilValue(assignmentState);
  const setReport = useSetRecoilState(assignmentState);
  const { isDesktop } = useResize();
  const { handleAPIError } = useAPIError();
  const [loading, setLoading] = useState<boolean>(false);

  const styles = useMemo(() => {
    return {
      tableCell: { width: isDesktop ? 50 : 25, padding: isDesktop ? 16 : 10 },
      tableCellWide: {
        width: isDesktop ? 250 : 100,
        padding: isDesktop ? 16 : 10,
      },
      tableCellMedium: {
        width: isDesktop ? 150 : 50,
        padding: isDesktop ? 16 : 10,
      },
    };
  }, [isDesktop]);

  const getFailReason = (tracked: string, estimate: string) => {
    if (estimate === '0:00') return 'Нет оценки времени по задаче';
    if (tracked > estimate) return 'Превышена оценка времени по задаче';
    return false;
  };

  const getDiff = (tracked: string, estimate: string) => {
    if (!getFailReason(tracked, estimate)) return null;
    const diffHours = (
      Number(tracked.split(':')[0]) - Number(estimate.split(':')[0])
    ).toLocaleString('en-US', {
      minimumIntegerDigits: 2,
      useGrouping: false,
    });
    const diffMinutes =
      Number(tracked.split(':')[1]) > Number(estimate.split(':')[1])
        ? (
            Number(tracked.split(':')[1]) - Number(estimate.split(':')[1])
          ).toLocaleString('en-US', {
            minimumIntegerDigits: 2,
            useGrouping: false,
          })
        : (
            Number(tracked.split(':')[1]) -
            Number(estimate.split(':')[1]) +
            60
          ).toLocaleString('en-US', {
            minimumIntegerDigits: 2,
            useGrouping: false,
          });
    const result = `${diffHours}:${diffMinutes}`;
    return result;
  };

  const refreshCollab = () => {
    setLoading(true);
    async function refreshAssignments() {
      try {
        await Api.getRefresh(
          Api.routes.importApi.report(year.toString(), month.toString()),
          {}
        );
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        handleAPIError(error);
        setLoading(false);
      }
    }
    async function fetchAssignments() {
      try {
        const { results } = await Api.get(Api.routes.api.assignment(), {
          account_id: id,
          report_month: month.toString() || new Date().getMonth() + 1,
          report_year: year.toString() || new Date().getFullYear(),
          is_not_counted: 1,
        });
        results.sort((a: IReport, b: IReport) =>
          a.assignment.name.localeCompare(b.assignment.name)
        );
        setReport(results);
        setLoading(false);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        handleAPIError(error);
        setLoading(false);
      }
    }

    refreshAssignments();
    fetchAssignments();
  };

  return (
    <>
      <LoadingLayout isLoading={loading} />
      <AssignmentEffect month={month} year={year} id={id} notCounted />
      <Breadcrumbs
        lastName={`${MONTHS[month || new Date().getMonth() + 1]} - ${name}`}
      />
      <Typography variant="h4" sx={{ margin: '25px 0' }}>
        {MONTHS[month || new Date().getMonth() + 1]} - {name} - Не зачтено
      </Typography>
      <Button
        variant="outlined"
        size="small"
        onClick={refreshCollab}
        sx={{ margin: '20px 0' }}
      >
        Обновить данные с Active Collab
      </Button>
      <TableContainer component={Paper}>
        <Table sx={{ maxWidth: '100%' }} aria-label="projects table">
          <TableHead>
            <TableRow>
              <TableCell size={isDesktop ? 'medium' : 'small'}>
                Задача (списанные/оцененные часы)
              </TableCell>
              <TableCell size={isDesktop ? 'medium' : 'small'} align="right">
                Не зачтено
              </TableCell>
              <TableCell size={isDesktop ? 'medium' : 'small'} align="left">
                Причины
              </TableCell>
              <TableCell size={isDesktop ? 'medium' : 'small'} align="right">
                Менеджер/Инициатор
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {report.map((row) => (
              <TableRow
                key={row.id}
                sx={{
                  textDecoration: 'none',
                }}
              >
                <TableCell
                  style={styles.tableCellMedium}
                  component="th"
                  scope="row"
                >
                  {row.name} ({row.tracked_time} / {row.estimated_time})
                </TableCell>
                <TableCell style={styles.tableCell} align="right">
                  {getDiff(row.tracked_time, row.estimated_time)}
                </TableCell>
                <TableCell
                  style={styles.tableCellMedium}
                  sx={{
                    backgroundColor:
                      row.estimated_time === '0:00' ? '#fae6e6' : 'transparent',
                  }}
                  align="left"
                >
                  {getFailReason(row.tracked_time, row.estimated_time)}
                </TableCell>
                <TableCell style={styles.tableCell} align="right">
                  {row.manager?.full_name || ''}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Typography sx={{ marginTop: '50px' }}>Всего {report.length}</Typography>
    </>
  );
}
