import { atom } from 'recoil';

export interface IReport {
  id: number;
  description?: string;
  value: string;
  assignment: {
    name: string;
    external_link: string;
    is_one_to_one: boolean;
    is_closed: boolean;
    estimated_time: string;
    tracked_time: string;
  };
}

export const reportState = atom<IReport[]>({
  key: 'Report',
  default: [],
});
