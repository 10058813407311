/* eslint-disable camelcase */
import React from 'react';
import { Paper, Typography, Stack, Button } from '@mui/material';
import { useRecoilValue } from 'recoil';
import { unitsState } from 'Recoil/Atoms/Units';
import userState from 'Recoil/Atoms/User';
import UserEffect from 'Recoil/Effects/UserEffect';
import useResize from 'Hooks/useResize';
import Breadcrumbs from 'Components/Breadcrumbs';
import { grey } from '@mui/material/colors';
import { ROLES } from 'Dictionary/roles';
import { useNavigate } from 'react-router-dom';
import ROUTES from 'Dictionary/routes';

export default function PersonalAccount() {
  const { first_name, last_name, email, extra_emails, role, unit_id } =
    useRecoilValue(userState);
  const units = useRecoilValue(unitsState);
  const { isDesktop } = useResize();
  const navigate = useNavigate();

  const stackStyle = {
    marginBottom: '10px',
  };

  const descriptionStyle = {
    color: grey[600],
    marginLeft: '5px',
    alignSelf: 'center',
    width: '50%',
    wordBreak: 'break-all',
  };

  const titleStyle = {
    fontWeight: '700',
    width: '50%',
  };

  return (
    <>
      <Breadcrumbs />
      <UserEffect />
      <Typography variant="h4" sx={{ margin: '25px 0' }}>
        Личный кабинет
      </Typography>
      <Paper
        sx={{
          color: grey[700],
          marginX: isDesktop ? '30%' : '',
          marginTop: '20px',
          padding: '20px',
        }}
      >
        <Stack direction="row" sx={stackStyle}>
          <Typography sx={titleStyle}>Имя:</Typography>
          <Typography sx={descriptionStyle}>{first_name}</Typography>
        </Stack>
        <Stack direction="row" sx={stackStyle}>
          <Typography sx={titleStyle}>Фамилия:</Typography>
          <Typography sx={descriptionStyle}>{last_name}</Typography>
        </Stack>
        <Stack direction="row" sx={stackStyle}>
          <Typography sx={titleStyle}>Корпоративная почта:</Typography>
          <Typography sx={descriptionStyle}>{email}</Typography>
        </Stack>
        {extra_emails &&
          extra_emails.map((elem: string, index: number) => (
            <Stack direction="row" sx={stackStyle}>
              <Typography sx={titleStyle}>Доп. почта {index}:</Typography>
              <Typography sx={descriptionStyle}>{elem}</Typography>
            </Stack>
          ))}
        <Stack direction="row" sx={stackStyle}>
          <Typography sx={titleStyle}>Роль:</Typography>
          <Typography sx={descriptionStyle}>
            {ROLES[role as keyof typeof ROLES]}
          </Typography>
        </Stack>
        <Stack direction="row" sx={stackStyle}>
          <Typography sx={titleStyle}>Подразделение:</Typography>
          <Typography sx={descriptionStyle}>
            {units.find((unit) => unit.id === unit_id)?.name}
          </Typography>
        </Stack>
        <Stack direction="row" justifyContent="flex-end">
          <Button
            sx={{
              width: 'fit-content',
              height: 'fit-content',
              padding: '5px',
              marginLeft: '20px',
            }}
            onClick={() => navigate(ROUTES.accountUpdate())}
          >
            Редактировать
          </Button>
        </Stack>
      </Paper>
    </>
  );
}
