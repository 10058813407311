const CRUMBS = {
  reports: 'Отчеты',
  report: 'Отчеты',
  projects: 'Проекты',
  auth: 'Авторизация',
  signup: 'Логин',
  estimate: 'Изменения оценки',
  units: 'Подразделения',
  users: 'Пользователи',
  account: 'Личный кабинет',
  holidays: 'Выходные',
  timetable: 'Расписание',
  'failed-report': 'Отчеты',
  'estimate-report': 'Отчеты',
  'budget-report': 'Отчет по бюджету',
  'my-timetable': 'Мое расписание',
};

export default CRUMBS;
