export const ROLES = {
  administrator: 'Администратор',
  user: 'Пользователь',
  manager: 'Менеджер',
};

export const USERS = ['user', 'client', 'manager', 'administrator'];

export const ADMINISTRATORS = ['administrator'];

export const MANAGERS = ['manager'];
