import { ADMINISTRATORS, MANAGERS } from 'Dictionary/roles';
import ROUTES from 'Dictionary/routes';
import React from 'react';
import { Navigate } from 'react-router-dom';

interface IManagerRoute {
  children: JSX.Element;
  role: string;
}

export default function ManagerRoute({ children, role }: IManagerRoute) {
  if (MANAGERS.includes(role) || ADMINISTRATORS.includes(role)) {
    return children;
  }
  return <Navigate to={ROUTES.reports()} replace />;
}
