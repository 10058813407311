import { atom } from 'recoil';

export interface IDay {
  id: number;
  project_name: string;
  date: string;
  value: string;
  parent_url: string;
  parent_name: string;
  description: string;
}

export interface ITimetable {
  [key: string]: IDay[];
}

export const timetableState = atom<ITimetable>({
  key: 'Timetable',
  default: {},
});
