import { useSetRecoilState } from 'recoil';
import { useEffect } from 'react';
import Api from 'Api';
import useAPIError from 'Hooks/useAPIError';

import { assignmentState } from 'Recoil/Atoms/Assignment';

interface IProps {
  month?: number;
  year?: number;
  id?: number;
  notCounted?: boolean;
}

export function AssignmentEffect({ month, year, id, notCounted }: IProps) {
  const setAssignmentState = useSetRecoilState(assignmentState);
  const { handleAPIError } = useAPIError();

  useEffect(() => {
    async function fetchAssignment() {
      try {
        const { results } = await Api.get(Api.routes.api.assignment(), {
          account_id: id,
          month: month || new Date().getMonth() + 1,
          year: year || new Date().getFullYear(),
          is_not_counted: Number(notCounted) || 0,
        });
        setAssignmentState(results);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        handleAPIError(error);
      }
    }

    fetchAssignment();
  }, [month]);

  return null;
}
