import { useSetRecoilState } from 'recoil';
import { useEffect } from 'react';
import Api from 'Api';
import useAPIError from 'Hooks/useAPIError';
import { holidaysState } from 'Recoil/Atoms/Holidays';

interface IProps {
  month?: number;
  year?: number;
}

export function HolidaysEffect({ month, year }: IProps) {
  const setHolidaysState = useSetRecoilState(holidaysState);
  const { handleAPIError } = useAPIError();

  useEffect(() => {
    async function fetchHolidays() {
      try {
        const { results } = await Api.get(Api.routes.api.holidays(), {
          month,
          year,
        });
        setHolidaysState(results);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        handleAPIError(error);
      }
    }

    fetchHolidays();
  }, [month]);

  return null;
}
