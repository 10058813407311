import { atom } from 'recoil';

export interface IUnit {
  status: boolean;
  name: string;
  id?: number;
  dialog?: 'delete' | 'save' | 'add';
}

export const unitState = atom<IUnit>({
  key: 'Unit',
  default: {
    status: false,
    name: '',
  },
});
