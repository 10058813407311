/* eslint-disable camelcase */
import { useSetRecoilState } from 'recoil';
import { useEffect } from 'react';
import Api from 'Api';
import useAPIError from 'Hooks/useAPIError';

import { reportsState, IReports } from 'Recoil/Atoms/Reports';

interface IProps {
  month?: number;
  year?: number;
  account_id?: number;
}

export function ReportsEffect({ month, year, account_id }: IProps) {
  const setReportsState = useSetRecoilState(reportsState);
  const { handleAPIError } = useAPIError();

  useEffect(() => {
    async function fetchReports() {
      try {
        const { results } = await Api.get(Api.routes.api.reports(), {
          month: month || new Date().getMonth() + 1,
          year: year || new Date().getFullYear(),
          ...(account_id && { account_id }),
        });
        results.sort((a: IReports, b: IReports) =>
          a.account.full_name.localeCompare(b.account.full_name)
        );
        setReportsState(results);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        handleAPIError(error);
      }
    }

    fetchReports();
  }, [month]);

  return null;
}
