import { atom } from 'recoil';

export interface IUnit {
  name: string;
}

export interface IUnits {
  id: number;
  hours: number;
  remaining_hours: number;
  cost: number;
  cost_per_hour: number;
  unit: IUnit;
}

export interface IBudgetReport {
  [key: string]: IUnits[];
}

export const budgetReportState = atom<IBudgetReport>({
  key: 'BudgetReport',
  default: {},
});
