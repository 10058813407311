const ROUTES = {
  home: () => '/',
  reports: () => '/reports',
  report: (id = ':id') => `/report/${id}`,
  estimateReport: (id = ':id') => `/estimate-report/${id}`,
  failedReport: (id = ':id') => `/failed-report/${id}`,
  projects: () => '/projects',
  project: (id = ':id') => `/projects/${id}`,
  holidays: () => '/reports/holidays',
  auth: () => '/signup',
  estimate: () => '/estimate',
  units: () => '/units',
  users: () => '/users',
  account: () => '/account',
  accountUpdate: () => '/account/update',
  budgetReport: () => '/budget-report',
  timetable: (id = ':id') => `/users/timetable/${id}`,
  myTimetable: () => `/my-timetable/`,
};

export default ROUTES;
