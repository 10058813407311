import React, { useEffect, useMemo, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableHead from '@mui/material/TableHead';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import useResize from 'Hooks/useResize';
import LastPageIcon from '@mui/icons-material/LastPage';
import { estimatesState } from 'Recoil/Atoms/Estimate';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import MonthPicker from 'Components/MonthPicker';
import Breadcrumbs from 'Components/Breadcrumbs';
import { red, grey, green, yellow } from '@mui/material/colors';
import Api from 'Api';
import LoadingLayout from 'Components/Layout';
import useAPIError from 'Hooks/useAPIError';

interface TablePaginationActionsProps {
  count: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (
    event: React.MouseEvent<HTMLButtonElement>,
    newPage: number
  ) => void;
}

function TablePaginationActions(props: TablePaginationActionsProps) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

export default function Estimate() {
  const estimates = useRecoilValue(estimatesState);
  const setEstimates = useSetRecoilState(estimatesState);
  const { isDesktop } = useResize();
  const [page, setPage] = useState(0);
  const [month, setMonth] = useState(new Date().getMonth() + 1);
  const [year, setYear] = useState(new Date().getFullYear());
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const { handleAPIError } = useAPIError();
  const [loading, setLoading] = useState<boolean>(false);

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - estimates.length) : 0;

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    async function fetchEstimates() {
      try {
        const { results } = await Api.get(Api.routes.api.estimate(), {
          report_month: month || new Date().getMonth() + 1,
          report_year: year || new Date().getFullYear(),
        });
        setEstimates(results);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        handleAPIError(error);
      }
    }

    fetchEstimates();
  }, []);

  const formatDate = (value: string) => {
    return `${value.substr(8, 2)}.${value.substr(5, 2)} ${value.substr(11, 5)}`;
  };

  const rowsData = useMemo(() => {
    return rowsPerPage > 0
      ? estimates.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      : estimates;
  }, [rowsPerPage, estimates, page]);

  const styles = useMemo(() => {
    return {
      tableCell: { width: isDesktop ? 50 : 25, padding: isDesktop ? 16 : 10 },
      tableCellWide: {
        width: isDesktop ? 250 : 100,
        padding: isDesktop ? 16 : 10,
      },
      tableCellMedium: {
        width: isDesktop ? 150 : 50,
        padding: isDesktop ? 16 : 10,
      },
      paginationText: isDesktop ? 'Показывать на странице' : '',
    };
  }, [isDesktop]);

  const refreshCollab = () => {
    setLoading(true);
    async function refreshReports() {
      try {
        await Api.get(
          Api.routes.importApi.report(year.toString(), month.toString()),
          {}
        );
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        handleAPIError(error);
        setLoading(false);
      }
    }
    async function fetchEstimates() {
      try {
        const { results } = await Api.get(Api.routes.api.estimate(), {
          report_month: month || new Date().getMonth() + 1,
          report_year: year || new Date().getFullYear(),
        });
        setEstimates(results);
        setLoading(false);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        handleAPIError(error);
        setLoading(false);
      }
    }

    refreshReports();
    fetchEstimates();
  };

  return (
    <>
      <LoadingLayout isLoading={loading} />
      <Breadcrumbs />
      <Typography variant="h4" sx={{ margin: '25px 0' }}>
        Изменения оценки
      </Typography>
      <Button
        variant="outlined"
        size="small"
        onClick={refreshCollab}
        sx={{ margin: '20px 0' }}
      >
        Обновить данные с Active Collab
      </Button>
      <MonthPicker
        month={month}
        monthChange={setMonth}
        year={year}
        yearChange={setYear}
      />
      <TableContainer component={Paper}>
        <Table sx={{ maxWidth: '100%' }} aria-label="projects table">
          <TableHead>
            <TableRow>
              <TableCell size={isDesktop ? 'medium' : 'small'}>
                {isDesktop ? 'Дата изменения' : 'Дата'}
              </TableCell>
              <TableCell size={isDesktop ? 'medium' : 'small'} align="left">
                Исполнитель
              </TableCell>
              <TableCell size={isDesktop ? 'medium' : 'small'} align="left">
                {isDesktop ? 'Название проекта и задачи' : 'Название'}
              </TableCell>
              <TableCell size={isDesktop ? 'medium' : 'small'} align="right">
                Старая оценка
              </TableCell>
              <TableCell size={isDesktop ? 'medium' : 'small'} align="right">
                Новая оценка
              </TableCell>
              <TableCell size={isDesktop ? 'medium' : 'small'} align="right">
                {isDesktop ? 'Потраченное время' : 'Потрачено'}
              </TableCell>
              <TableCell size={isDesktop ? 'medium' : 'small'} align="left">
                Кто изменил
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rowsData.map((row) => (
              <TableRow
                key={row.id}
                onClick={() => {
                  window.open(row.assignment.external_link, '_blank');
                }}
                sx={{
                  textDecoration: 'none',
                  '&:hover': {
                    backgroundColor: grey[300],
                  },
                }}
              >
                <TableCell style={styles.tableCell} component="th" scope="row">
                  {formatDate(row.change_timestamp)}
                </TableCell>
                <TableCell style={styles.tableCellMedium} align="left">
                  {row.user.full_name || ''}
                </TableCell>
                <TableCell align="left">
                  {row.assignment.name || ''}
                  {row.assignment_is_closed && (
                    <Typography sx={{ color: red[500], fontSize: 14 }}>
                      (закрыто)
                    </Typography>
                  )}
                </TableCell>
                <TableCell
                  sx={{ backgroundColor: yellow[100] }}
                  style={styles.tableCell}
                  align="right"
                >
                  {row.old_value || ''}
                </TableCell>
                <TableCell
                  sx={
                    row.new_value >= row.old_value
                      ? { backgroundColor: red[100] }
                      : { backgroundColor: green[100] }
                  }
                  style={styles.tableCell}
                  align="right"
                >
                  {row.new_value}
                </TableCell>
                <TableCell style={styles.tableCell} align="right">
                  {row.tracked_time || ''}
                </TableCell>
                <TableCell style={styles.tableCellMedium} align="left">
                  {row.initiator?.full_name || ''}
                </TableCell>
              </TableRow>
            ))}
            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[10, 25, 50, { label: 'Все', value: -1 }]}
                colSpan={isDesktop ? 7 : 4}
                count={estimates.length}
                rowsPerPage={rowsPerPage}
                page={page}
                labelRowsPerPage={styles.paginationText}
                SelectProps={{
                  inputProps: {
                    'aria-label': 'rows per page',
                  },
                  native: true,
                }}
                labelDisplayedRows={({ from, to, count }) =>
                  isDesktop ? `${from}-${to} из ${count}` : ''
                }
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </>
  );
}
