import { atom } from 'recoil';

export interface IReports {
  id: number;
  account_id: number;
  total: number;
  accepted: number;
  declined: number;
  payment_sum: string;
  account: {
    id: number;
    full_name: string;
  };
}

export const reportsState = atom<IReports[]>({
  key: 'Reports',
  default: [],
});
