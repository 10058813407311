/* eslint-disable react/jsx-no-useless-fragment */
import React, { forwardRef } from 'react';
import { TextField } from '@mui/material';

interface IInput extends React.InputHTMLAttributes<HTMLInputElement> {
  type?: string;
  placeholder?: string;
  label?: string;
  value?: string;
  sx?: object;
  name: string;
}

function Input(
  {
    type = 'text',
    value,
    required,
    onChange,
    name,
    label,
    placeholder,
    sx,
  }: IInput,
  ref: React.Ref<HTMLInputElement>
) {
  return (
    <TextField
      name={name}
      onChange={onChange}
      value={value}
      label={label}
      placeholder={placeholder}
      required={required}
      type={type}
      sx={sx}
    />
  );
}

export default forwardRef<HTMLInputElement, IInput>(Input);
