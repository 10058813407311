import React from 'react';
import AuthButton from 'Components/AuthButton';
import { Box, Typography } from '@mui/material';
import Breadcrumbs from 'Components/Breadcrumbs';

export default function SignUp() {
  return (
    <>
      <Breadcrumbs />
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography sx={{ margin: '50px 0' }}>
          Для пользования сайтом необходимо авторизоваться, используя рабочую
          почту Google.
        </Typography>
        <AuthButton />
      </Box>
    </>
  );
}
