import React, { useState, useEffect, useMemo } from 'react';
import Button from '@mui/material/Button';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { IUsers, usersState } from 'Recoil/Atoms/Users';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import Breadcrumbs from 'Components/Breadcrumbs';
import { blue, grey } from '@mui/material/colors';
import useResize from 'Hooks/useResize';
import { ADMINISTRATORS, ROLES } from 'Dictionary/roles';
import { useNavigate } from 'react-router-dom';
import ROUTES from 'Dictionary/routes';
import { Typography } from '@mui/material';
import Api from 'Api';
import useAPIError from 'Hooks/useAPIError';
import userState from 'Recoil/Atoms/User';
import LoadingLayout from 'Components/Layout';

export default function Users() {
  const navigate = useNavigate();
  const users = useRecoilValue(usersState);
  const [loading, setLoading] = useState<boolean>(false);
  const setUsers = useSetRecoilState(usersState);
  const { role } = useRecoilValue(userState);
  const { isDesktop } = useResize();
  const { handleAPIError } = useAPIError();

  useEffect(() => {
    async function fetchUsers() {
      try {
        const { results } = await Api.get(Api.routes.api.users(), {});
        results.sort((a: IUsers, b: IUsers) =>
          a.first_name.localeCompare(b.first_name)
        );
        setUsers(results);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        handleAPIError(error);
      }
    }

    fetchUsers();
  }, []);

  const styles = useMemo(() => {
    return {
      tableCell: { width: isDesktop ? 50 : 25, padding: isDesktop ? 16 : 10 },
      tableCellWide: {
        width: isDesktop ? 250 : 100,
        padding: isDesktop ? 16 : 10,
      },
      tableCellMedium: {
        width: isDesktop ? 150 : 50,
        padding: isDesktop ? 16 : 10,
      },
      paginationText: isDesktop ? 'Показывать на странице' : '',
    };
  }, [isDesktop]);

  const refreshCollab = () => {
    setLoading(true);
    async function refreshUsers() {
      try {
        await Api.getRefresh(Api.routes.importApi.users(), {});
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        handleAPIError(error);
        setLoading(false);
      }
    }
    async function fetchUsers() {
      try {
        const { results } = await Api.get(Api.routes.api.users(), {});
        results.sort((a: IUsers, b: IUsers) =>
          a.first_name.localeCompare(b.first_name)
        );
        setUsers(results);
        setLoading(false);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        handleAPIError(error);
        setLoading(false);
      }
    }

    refreshUsers();
    fetchUsers();
  };

  const loginAsUser = (id: number) => {
    async function login() {
      try {
        const { result } = await Api.get(
          Api.routes.api.loginAsUser(id.toString()),
          {}
        );
        if (result.success) {
          window.location.reload();
        }
      } catch (error: any) {
        handleAPIError(error);
      }
    }

    login();
  };

  return (
    <>
      <LoadingLayout isLoading={loading} />
      <Breadcrumbs />
      <Typography variant="h4" sx={{ margin: '25px 0' }}>
        Пользователи
      </Typography>
      <Button
        onClick={refreshCollab}
        variant="outlined"
        size="small"
        sx={{ margin: '20px 0' }}
      >
        Обновить данные с Active Collab
      </Button>
      <TableContainer component={Paper}>
        <Table sx={{ maxWidth: '100%' }} aria-label="users table">
          <TableHead>
            <TableRow>
              <TableCell size={isDesktop ? 'medium' : 'small'}>#</TableCell>
              <TableCell size={isDesktop ? 'medium' : 'small'} align="left">
                Имя
              </TableCell>
              <TableCell size={isDesktop ? 'medium' : 'small'} align="left">
                Email
              </TableCell>
              <TableCell size={isDesktop ? 'medium' : 'small'} align="right">
                Подразделение
              </TableCell>
              <TableCell size={isDesktop ? 'medium' : 'small'} align="right">
                Расписание
              </TableCell>
              <TableCell size={isDesktop ? 'medium' : 'small'} align="right">
                Роль
              </TableCell>
              {ADMINISTRATORS.includes(role || 'user') && (
                <TableCell size={isDesktop ? 'medium' : 'small'} align="right">
                  Войти
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((row) => (
              <TableRow
                key={row.id}
                sx={{
                  opacity: row.status !== 'archive' ? 1 : 0.3,
                  textDecoration: 'none',
                  '&:hover': {
                    backgroundColor: grey[300],
                  },
                }}
              >
                <TableCell style={styles.tableCell} component="th" scope="row">
                  {row.external_user_id}
                </TableCell>
                <TableCell
                  onClick={() => {
                    navigate(ROUTES.report(`${row.id}`), {
                      state: { name: row.full_name, id: row.id },
                    });
                  }}
                  sx={{
                    color: blue[500],
                    '&:hover': {
                      cursor: 'pointer',
                      color: blue[300],
                    },
                  }}
                  style={styles.tableCellMedium}
                  align="left"
                >
                  {row.full_name}
                </TableCell>
                <TableCell style={styles.tableCellWide} align="left">
                  {row.email || ''}
                </TableCell>
                <TableCell style={styles.tableCell} align="right">
                  {row?.unit?.name || ''}
                </TableCell>
                <TableCell
                  onClick={() =>
                    navigate(ROUTES.timetable(row.id.toString()), {
                      state: {
                        externalUserID: row.id,
                        externalName: row.full_name,
                      },
                    })
                  }
                  sx={{
                    color: blue[500],
                    '&:hover': {
                      cursor: 'pointer',
                      color: blue[300],
                    },
                  }}
                  style={styles.tableCell}
                  align="right"
                >
                  Расписание
                </TableCell>
                <TableCell style={styles.tableCell} align="right">
                  {(row.role && ROLES[row.role as keyof typeof ROLES]) || ''}
                </TableCell>
                {ADMINISTRATORS.includes(role || 'user') && (
                  <TableCell
                    style={styles.tableCell}
                    sx={{
                      color: blue[500],
                      '&:hover': {
                        cursor: 'pointer',
                        color: blue[300],
                      },
                    }}
                    onClick={() => loginAsUser(row.id)}
                    align="right"
                  >
                    Войти
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Typography sx={{ marginTop: '50px' }}>Всего {users.length}</Typography>
    </>
  );
}
