import { IUser } from 'Recoil/Atoms/User';

export default function setDefaultValues(object: IUser) {
  return {
    email: object?.email || '',
    first_name: object?.first_name || '',
    last_name: object?.last_name || '',
    is_email_receiver: object?.is_email_receiver || false,
  };
}
