import { ADMINISTRATORS } from 'Dictionary/roles';
import ROUTES from 'Dictionary/routes';
import React from 'react';
import { Navigate } from 'react-router-dom';

interface IAdministratorRoute {
  children: JSX.Element;
  role: string;
}

export default function AdministratorRoute({
  children,
  role,
}: IAdministratorRoute) {
  if (ADMINISTRATORS.includes(role)) {
    return children;
  }
  return <Navigate to={ROUTES.reports()} replace />;
}
