/* eslint-disable camelcase */
/* eslint-disable import/no-unresolved */
import React, { useState, useMemo } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import Button from '@mui/material/Button';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import useResize from 'Hooks/useResize';
import userState from 'Recoil/Atoms/User';
import { assignmentState } from 'Recoil/Atoms/Assignment';
import { AssignmentEffect } from 'Recoil/Effects/AssignmentEffect';
import { ReportsEffect } from 'Recoil/Effects/ReportsEffect';
import { reportsState } from 'Recoil/Atoms/Reports';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useLocation } from 'react-router-dom';
import { Stack, TextField, Typography } from '@mui/material';
import MONTHS from 'Dictionary/months';
import Breadcrumbs from 'Components/Breadcrumbs';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import MomentAdapter from '@date-io/moment';
import useAPIError from 'Hooks/useAPIError';
import { ADMINISTRATORS } from 'Dictionary/roles';
import moment from 'moment';
import 'moment/locale/ru';
import Api from 'Api';
import { IReport } from 'Recoil/Atoms/Report';
import LoadingLayout from 'Components/Layout';

export default function EstimateReport() {
  moment.locale('ru');
  const dateFormat = 'D MMM yyyy';

  interface IFormData {
    dateFrom: string | null;
    dateTo: string | null;
  }

  const { state } = useLocation();
  const { id, reportMonth, reportYear, name } = state;
  const report = useRecoilValue(assignmentState);
  const setReport = useSetRecoilState(assignmentState);
  const reports = useRecoilValue(reportsState);
  const setReportsState = useSetRecoilState(reportsState);
  const { role } = useRecoilValue(userState);
  const { isDesktop } = useResize();
  const { handleAPIError } = useAPIError();
  const [loading, setLoading] = useState<boolean>(false);
  const [payment, setPayment] = useState<string>('');
  const [formData, setFormData] = useState<IFormData>({
    dateFrom: null,
    dateTo: null,
  });

  const styles = useMemo(() => {
    return {
      tableCell: { width: isDesktop ? 50 : 25, padding: isDesktop ? 16 : 10 },
      tableCellWide: {
        width: isDesktop ? 250 : 100,
        padding: isDesktop ? 16 : 10,
      },
      tableCellMedium: {
        width: isDesktop ? 150 : 50,
        padding: isDesktop ? 16 : 10,
      },
      titleFormStyle: {
        width: '150px',
        alignSelf: 'center',
      },
      inputFormStyle: {
        width: '100%',
      },
    };
  }, [isDesktop]);

  async function handleAddVacation() {
    try {
      await Api.post(Api.routes.api.vacation(), {
        account_id: id,
        first_day: moment(formData.dateFrom).format(),
        last_day: moment(formData.dateTo).format(),
      });
      setFormData({
        dateFrom: null,
        dateTo: null,
      });
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      handleAPIError(error);
    }
  }

  async function handleAddPayment(newPayment: string) {
    const parsedMinutes =
      Math.round((Number(newPayment.split(':')[1]) / 60) * 100) / 100;
    const parsedHours = Number(newPayment.split(':')[0]);
    const parsedValue = parsedHours + parsedMinutes;
    try {
      await Api.post(Api.routes.api.payments(), {
        account_id: id,
        year: reportYear.toString(),
        month: reportMonth.toString(),
        payment_sum: Number(parsedValue),
      });
      const payment_sum = parsedValue.toString();
      setReportsState(
        reports.map((elem) =>
          elem.account_id === id ? { ...elem, payment_sum } : elem
        )
      );
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      handleAPIError(error);
    }
  }

  const refreshCollab = () => {
    setLoading(true);
    async function refreshAssignments() {
      try {
        await Api.getRefresh(
          Api.routes.importApi.report(
            reportYear.toString(),
            reportMonth.toString()
          ),
          {}
        );
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        handleAPIError(error);
        setLoading(false);
      }
    }
    async function fetchAssignments() {
      try {
        const { results } = await Api.get(Api.routes.api.assignment(), {
          account_id: id,
          report_month: reportMonth.toString() || new Date().getMonth() + 1,
          report_year: reportYear.toString() || new Date().getFullYear(),
          is_not_counted: 0,
        });
        results.sort((a: IReport, b: IReport) =>
          a.assignment.name.localeCompare(b.assignment.name)
        );
        setReport(results);
        setLoading(false);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        handleAPIError(error);
        setLoading(false);
      }
    }

    refreshAssignments();
    fetchAssignments();
  };

  return (
    <>
      <LoadingLayout isLoading={loading} />
      <AssignmentEffect
        month={reportMonth}
        year={reportYear}
        id={id}
        notCounted={false}
      />
      <ReportsEffect year={reportYear} month={reportMonth} account_id={id} />
      <Breadcrumbs
        lastName={`${
          MONTHS[reportMonth || new Date().getMonth() + 1]
        } - ${name}`}
      />
      <Typography variant="h4" sx={{ margin: '25px 0' }}>
        {MONTHS[reportMonth || new Date().getMonth() + 1]} - {name} -
        Утверждение задач
      </Typography>
      {ADMINISTRATORS.includes(role || 'user') && (
        <Stack
          direction={isDesktop ? 'row' : 'column'}
          spacing={isDesktop ? 6 : 3}
          sx={{ marginTop: '25px' }}
        >
          <Stack direction="column" spacing={2} sx={{ maxWidth: '400px' }}>
            <LocalizationProvider
              dateAdapter={MomentAdapter}
              dateFormats={{
                normalDate: dateFormat,
                keyboardDate: dateFormat,
              }}
            >
              <Stack direction="row" spacing={2}>
                <Typography style={styles.titleFormStyle}>Отпуск с</Typography>
                <DatePicker
                  label="Дата"
                  value={formData.dateFrom}
                  onChange={(newValue) =>
                    setFormData({ ...formData, dateFrom: newValue })
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                      style={styles.inputFormStyle}
                    />
                  )}
                  disableMaskedInput
                />
              </Stack>
              <Stack direction="row" spacing={2}>
                <Typography style={styles.titleFormStyle}>Отпуск до</Typography>
                <DatePicker
                  label="Дата"
                  value={formData.dateTo}
                  onChange={(newValue) =>
                    setFormData({ ...formData, dateTo: newValue })
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      required
                      style={styles.inputFormStyle}
                    />
                  )}
                  disableMaskedInput
                />
              </Stack>
              <Button
                variant="contained"
                size="small"
                sx={{ width: '100%' }}
                onClick={() => handleAddVacation()}
              >
                Добавить
              </Button>
            </LocalizationProvider>
          </Stack>
          <Stack direction="column" spacing={2} sx={{ maxWidth: '400px' }}>
            <Stack direction="row" spacing={2}>
              <Typography style={styles.titleFormStyle}>
                Оплачено часов
              </Typography>
              <TextField
                style={styles.inputFormStyle}
                value={payment}
                placeholder="00:00"
                onChange={(event) => setPayment(event.target.value)}
              />
            </Stack>
            <Button
              variant="contained"
              size="small"
              sx={{ width: '100%' }}
              onClick={() => handleAddPayment(payment)}
            >
              Сохранить
            </Button>
          </Stack>
          <Stack>
            <Typography>
              Всего часов: <strong>{reports[0].accepted}</strong>
            </Typography>
            <Typography>
              Оплачено часов: <strong>{reports[0].payment_sum}</strong>
            </Typography>
          </Stack>
        </Stack>
      )}
      <Button
        variant="outlined"
        size="small"
        onClick={refreshCollab}
        sx={{ margin: '20px 0' }}
      >
        Обновить данные с Active Collab
      </Button>
      <TableContainer component={Paper}>
        <Table sx={{ maxWidth: '100%' }} aria-label="projects table">
          <TableHead>
            <TableRow>
              <TableCell size={isDesktop ? 'medium' : 'small'}>
                Задача (списанные/оцененные часы)
              </TableCell>
              <TableCell size={isDesktop ? 'medium' : 'small'} align="right">
                Эстимейты в этом месяце
              </TableCell>
              <TableCell size={isDesktop ? 'medium' : 'small'} align="right">
                Менеджер/Инициатор
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {report.map((row) => (
              <TableRow
                key={row.id}
                sx={{
                  textDecoration: 'none',
                  backgroundColor: row.is_closed ? '#f0fff0' : '#fae6e6',
                }}
              >
                <TableCell
                  style={styles.tableCellMedium}
                  component="th"
                  scope="row"
                >
                  {row.name}{' '}
                  {row.is_one_to_one
                    ? '(1 к 1)'
                    : `${row.tracked_time}/${row.estimated_time}`}
                </TableCell>
                <TableCell style={styles.tableCell} align="right">
                  {row.month_accepted}
                </TableCell>
                <TableCell style={styles.tableCell} align="right">
                  {row.manager?.full_name || ''}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Typography sx={{ marginTop: '50px' }}>Всего {report.length}</Typography>
    </>
  );
}
