import * as params from './params';
import * as localStorage from './localStorage';
import * as apiTokenStorage from './apiTokenStorage';

export default {
  params,
  apiTokenStorage,
  localStorage,
  generateAuthHeader: () => {
    if (apiTokenStorage.get()) {
      return {
        Authorization: `Bearer ${apiTokenStorage.get()}`,
      };
    }
    return {};
  },
};
