import React, { useEffect, useMemo, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableHead from '@mui/material/TableHead';
import Button from '@mui/material/Button';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import useResize from 'Hooks/useResize';
import LastPageIcon from '@mui/icons-material/LastPage';
import { ProjectsEffect } from 'Recoil/Effects/ProjectsEffect';
import { projectsState } from 'Recoil/Atoms/Projects';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import Breadcrumbs from 'Components/Breadcrumbs';
import { grey } from '@mui/material/colors';
import { useNavigate } from 'react-router-dom';
import { Typography } from '@mui/material';
import Api from 'Api';
import LoadingLayout from 'Components/Layout';
import useAPIError from 'Hooks/useAPIError';

interface TablePaginationActionsProps {
  count: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (
    event: React.MouseEvent<HTMLButtonElement>,
    newPage: number
  ) => void;
}

function TablePaginationActions(props: TablePaginationActionsProps) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

export default function Projects() {
  const projects = useRecoilValue(projectsState);
  const setProjects = useSetRecoilState(projectsState);
  const { isDesktop } = useResize();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const navigate = useNavigate();
  const { handleAPIError } = useAPIError();
  const [loading, setLoading] = useState<boolean>(false);

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - projects.length) : 0;

  useEffect(() => {
    async function fetchProjects() {
      try {
        const { results } = await Api.get(Api.routes.api.projects());
        setProjects(results);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        handleAPIError(error);
      }
    }

    fetchProjects();
  }, []);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const rowsData = useMemo(() => {
    return rowsPerPage > 0
      ? projects.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      : projects;
  }, [rowsPerPage, projects, page]);

  const styles = useMemo(() => {
    return {
      tableCell: { width: isDesktop ? 50 : 25 },
      tableCellWide: { width: isDesktop ? 250 : 100 },
      paginationText: isDesktop ? 'Показывать на странице' : '',
    };
  }, [isDesktop]);

  const refreshCollab = () => {
    setLoading(true);
    async function refreshProjects() {
      try {
        await Api.get(Api.routes.importApi.project(), {});
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        handleAPIError(error);
        setLoading(false);
      }
    }
    async function fetchProjects() {
      try {
        const { results } = await Api.get(Api.routes.api.projects());
        setProjects(results);
        setLoading(false);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        handleAPIError(error);
        setLoading(false);
      }
    }

    refreshProjects();
    fetchProjects();
  };

  return (
    <>
      <LoadingLayout isLoading={loading} />
      <Breadcrumbs />
      <Typography variant="h4" sx={{ margin: '25px 0' }}>
        Проекты
      </Typography>
      <Button
        variant="outlined"
        size="small"
        onClick={refreshCollab}
        sx={{ margin: '20px 0' }}
      >
        Обновить данные с Active Collab
      </Button>
      <TableContainer component={Paper}>
        <Table sx={{ maxWidth: '100%' }} aria-label="projects table">
          <TableHead>
            <TableRow>
              <TableCell size={isDesktop ? 'medium' : 'small'}>#</TableCell>
              <TableCell size={isDesktop ? 'medium' : 'small'} align="left">
                Проект
              </TableCell>
              <TableCell size={isDesktop ? 'medium' : 'small'} align="right">
                Менеджер / Инициатор
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rowsData.map((row) => (
              <TableRow
                key={row.name}
                style={{ cursor: 'pointer' }}
                onClick={() =>
                  navigate(`/projects/${row.id}`, {
                    state: { name: row.name, id: row.id },
                  })
                }
                sx={{
                  textDecoration: 'none',
                  '&:hover': {
                    backgroundColor: grey[300],
                  },
                }}
              >
                <TableCell style={styles.tableCell} scope="row">
                  {row.id}
                </TableCell>
                <TableCell align="left">{row.name}</TableCell>
                <TableCell style={styles.tableCellWide} align="right">
                  {row.manager?.full_name}
                </TableCell>
              </TableRow>
            ))}
            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[10, 25, 50, { label: 'Все', value: -1 }]}
                colSpan={isDesktop ? 3 : 4}
                count={projects.length}
                rowsPerPage={rowsPerPage}
                page={page}
                labelRowsPerPage={styles.paginationText}
                SelectProps={{
                  inputProps: {
                    'aria-label': 'rows per page',
                  },
                  native: true,
                }}
                labelDisplayedRows={({ from, to, count }) =>
                  isDesktop ? `${from}-${to} из ${count}` : ''
                }
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </>
  );
}
