import React, { useEffect } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
} from '@mui/material';
import Breadcrumbs from 'Components/Breadcrumbs';
import useResize from 'Hooks/useResize';
import Api from 'Api';
import useAPIError from 'Hooks/useAPIError';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { budgetReportState } from 'Recoil/Atoms/BudgetReport';

export default function BudgetReport() {
  const budgetRaw = useRecoilValue(budgetReportState);
  const setBudgetReportState = useSetRecoilState(budgetReportState);
  const { isDesktop } = useResize();
  const budget = Object.entries(budgetRaw);
  const { handleAPIError } = useAPIError();

  useEffect(() => {
    async function fetchBudget() {
      try {
        const { results } = await Api.get(Api.routes.api.budgetReport());
        setBudgetReportState(results);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        handleAPIError(error);
      }
    }
    fetchBudget();
  }, []);

  return (
    <>
      <Breadcrumbs />
      <Typography variant="h4" sx={{ margin: '25px 0' }}>
        Отчет по бюджету
      </Typography>
      <TableContainer component={Paper}>
        <Table sx={{ maxWidth: '100%' }} aria-label="budget-report table">
          <TableHead>
            <TableRow>
              <TableCell size={isDesktop ? 'medium' : 'small'}>
                Название проекта/подразделения
              </TableCell>
              <TableCell size={isDesktop ? 'medium' : 'small'}>
                Заложено
              </TableCell>
              <TableCell size={isDesktop ? 'medium' : 'small'} />
              <TableCell size={isDesktop ? 'medium' : 'small'}>
                Осталось
              </TableCell>
              <TableCell size={isDesktop ? 'medium' : 'small'} />
            </TableRow>
          </TableHead>
          <TableBody>
            {budget.map((key) => (
              <>
                <TableRow key={key[0]}>
                  <TableCell
                    size={isDesktop ? 'medium' : 'small'}
                    sx={{ fontWeight: '700' }}
                  >
                    {key[0]}
                  </TableCell>
                  <TableCell size={isDesktop ? 'medium' : 'small'} />
                  <TableCell size={isDesktop ? 'medium' : 'small'} />
                  <TableCell size={isDesktop ? 'medium' : 'small'} />
                  <TableCell size={isDesktop ? 'medium' : 'small'} />
                </TableRow>
                {key[1].map((unit) => (
                  <TableRow key={unit.id}>
                    <TableCell size={isDesktop ? 'medium' : 'small'}>
                      {unit.unit.name}
                    </TableCell>
                    <TableCell size={isDesktop ? 'medium' : 'small'}>
                      {unit.hours.toString().replace('.', ':')} часов
                    </TableCell>
                    <TableCell size={isDesktop ? 'medium' : 'small'}>
                      {unit.cost.toLocaleString()} рублей
                    </TableCell>
                    <TableCell
                      size={isDesktop ? 'medium' : 'small'}
                      sx={{
                        backgroundColor:
                          unit.remaining_hours < 0 ? 'red' : 'inherit',
                      }}
                    >
                      {unit.remaining_hours.toString().replace('.', ':')} часов
                    </TableCell>
                    <TableCell
                      size={isDesktop ? 'medium' : 'small'}
                      sx={{
                        backgroundColor:
                          unit.remaining_hours < 0 ? 'red' : 'inherit',
                      }}
                    >
                      {(
                        unit.remaining_hours * unit.cost_per_hour
                      ).toLocaleString()}{' '}
                      рублей
                    </TableCell>
                  </TableRow>
                ))}
              </>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Typography sx={{ marginTop: '50px' }}>Всего {budget.length}</Typography>
    </>
  );
}
