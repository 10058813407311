import { atom } from 'recoil';
import Utilities from 'Utilities';

export interface IUser {
  logged: boolean;
  id?: number;
  role?: string;
  email?: string;
  status?: string;
  first_name?: string;
  last_name?: string;
  full_name?: string;
  unit_id?: number;
  extra_emails?: string[];
  external_user_id?: number;
  is_email_receiver?: boolean;
}

const userState = atom<IUser>({
  key: 'User',
  default: {
    logged: Utilities.apiTokenStorage.get(),
  } as IUser,
});

export default userState;
