import React from 'react';
import Main from 'Components/Main';
import Header from 'Components/Header';
import { BrowserRouter as Router } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import AtomEffects from 'Recoil/Effects';

export default function App() {
  return (
    <RecoilRoot>
      <Router>
        <AtomEffects />
        <Header />
        <Main />
      </Router>
    </RecoilRoot>
  );
}
