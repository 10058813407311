import { atom } from 'recoil';

export interface IUsers {
  id: number;
  role?: string;
  email?: string;
  full_name?: string;
  first_name: string;
  external_user_id?: number;
  unit?: {
    id: string;
    name: string;
  };
  index?: string;
  status?: string;
}

export const usersState = atom<IUsers[]>({
  key: 'Users',
  default: [],
});
