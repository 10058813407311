import { useSetRecoilState } from 'recoil';
import { useEffect } from 'react';
import Api from 'Api';
import useAPIError from 'Hooks/useAPIError';
import { timetableState } from 'Recoil/Atoms/Timetable';

interface IProps {
  month?: number;
  year?: number;
  id?: number;
}

export function TimetableEffect({ id, year, month }: IProps) {
  const setTimetableState = useSetRecoilState(timetableState);
  const { handleAPIError } = useAPIError();

  useEffect(() => {
    async function fetchTimetable() {
      try {
        const { results } = await Api.get(Api.routes.api.timetable(), {
          account_id: id,
          year,
          month,
        });
        setTimetableState(results);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        handleAPIError(error);
      }
    }
    fetchTimetable();
  }, [month]);

  return null;
}
