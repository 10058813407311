import React from 'react';
import Button from '@mui/material/Button';
import useUserActions from 'Hooks/useUserActions';
import ROUTES from 'Dictionary/routes';
import { useNavigate } from 'react-router-dom';
import Utilities from '../../Utilities';

export default function AuthButton() {
  const { fetchUser } = useUserActions();

  const navigate = useNavigate();

  const receiveMessage = async (event: MessageEvent) => {
    if (event.data.event === 'auth_token') {
      const { token } = event.data;
      Utilities.apiTokenStorage.set(token);
      fetchUser();
      navigate(ROUTES.reports());
      window.removeEventListener('message', receiveMessage);
    }
  };

  // урл в нижнем методе надо будет заменить на window.location.origin как только бэк и фронт окажутся в одном месте

  const handleGoogleSend = async () => {
    window.open(
      `${window.location.origin}/signin/google/auth`,
      'google auth',
      'width=700,height=500,top=100,left=100'
    );
    window.addEventListener('message', receiveMessage);
  };

  return <Button onClick={handleGoogleSend}>Войти через Google</Button>;
}
