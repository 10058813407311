/* eslint-disable camelcase */
/* eslint-disable import/no-unresolved */
import {
  Button,
  TextField,
  Typography,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material';
import Breadcrumbs from 'Components/Breadcrumbs';
import MonthPicker from 'Components/MonthPicker';
import React, { useState } from 'react';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import MomentAdapter from '@date-io/moment';
import { HolidaysEffect } from 'Recoil/Effects/HolidaysEffect';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { holidaysState } from 'Recoil/Atoms/Holidays';
import useResize from 'Hooks/useResize';
import moment from 'moment';
import 'moment/locale/ru';
import useAPIError from 'Hooks/useAPIError';
import Api from 'Api';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';

export default function Holidays() {
  moment.locale('ru');
  const dateFormat = 'D MMM yyyy';

  interface IFormData {
    dateFrom: string | null;
    dateTo: string | null;
    description: string;
  }

  const { isDesktop } = useResize();
  const holidays = useRecoilValue(holidaysState);
  const setHolidaysState = useSetRecoilState(holidaysState);
  const { handleAPIError } = useAPIError();
  const [month, setMonth] = useState(new Date().getMonth() + 1);
  const [year, setYear] = useState(new Date().getFullYear());
  const { control, handleSubmit, reset } = useForm<IFormData>();

  const titleFormStyle = {
    width: '150px',
    alignSelf: 'center',
  };

  const inputFormStyle = {
    width: '100%',
  };

  async function handleDeleteHoliday(id: number) {
    try {
      await Api.deleteRequest(Api.routes.api.holiday(id?.toString()));
      setHolidaysState(holidays.filter((elem) => elem.id !== id));
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      handleAPIError(error);
    }
  }

  const onSubmit: SubmitHandler<IFormData> = (data: IFormData) => {
    async function fetch() {
      try {
        const { results } = await Api.post(Api.routes.api.holidays(), {
          first_day: moment(data.dateFrom).format(),
          last_day: moment(data.dateTo).format(),
          description: data.description || '',
        });
        if (
          moment(data.dateFrom).format('M') === month.toString() ||
          moment(data.dateTo).format('M') === month.toString()
        ) {
          setHolidaysState((currState) => [
            ...currState,
            {
              id: results.id,
              first_day: results.first_day,
              last_day: results.last_day,
              description: results.description,
              days_last: results.days_last,
              initiator: { full_name: results.initiator.full_name },
            },
          ]);
        }
        reset();
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        handleAPIError(error);
      }
    }
    fetch();
  };

  return (
    <>
      <HolidaysEffect month={month} year={year} />
      <Breadcrumbs />
      <Typography variant="h4" sx={{ margin: '25px 0' }}>
        Выходные
      </Typography>
      <MonthPicker
        month={month}
        monthChange={setMonth}
        year={year}
        yearChange={setYear}
      />
      <Stack
        direction="column"
        spacing={2}
        sx={{ marginTop: '25px', maxWidth: '400px' }}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <LocalizationProvider
            dateAdapter={MomentAdapter}
            dateFormats={{
              normalDate: dateFormat,
              keyboardDate: dateFormat,
            }}
          >
            <Stack direction="row" spacing={2} sx={{ marginTop: '25px' }}>
              <Typography sx={titleFormStyle}>Выходные с</Typography>
              <Controller
                control={control}
                name="dateFrom"
                render={({ field: { ref, ...rest } }) => (
                  <DatePicker
                    ref={ref}
                    label="Дата"
                    inputFormat="DD.MM.yyyy"
                    renderInput={(params) => (
                      <TextField {...params} required sx={inputFormStyle} />
                    )}
                    disableMaskedInput
                    {...rest}
                  />
                )}
              />
            </Stack>
            <Stack direction="row" spacing={2} sx={{ marginTop: '25px' }}>
              <Typography sx={titleFormStyle}>Выходные по</Typography>
              <Controller
                control={control}
                name="dateTo"
                render={({ field: { ref, ...rest } }) => (
                  <DatePicker
                    ref={ref}
                    label="Дата"
                    inputFormat="DD.MM.yyyy"
                    renderInput={(params) => (
                      <TextField {...params} required sx={inputFormStyle} />
                    )}
                    disableMaskedInput
                    {...rest}
                  />
                )}
              />
            </Stack>
            <Stack direction="row" spacing={2} sx={{ marginTop: '25px' }}>
              <Typography sx={titleFormStyle}>Повод</Typography>
              <Controller
                name="description"
                control={control}
                defaultValue=""
                render={({ field: { ref, ...rest } }) => (
                  <TextField
                    ref={ref}
                    multiline
                    sx={inputFormStyle}
                    {...rest}
                  />
                )}
              />
            </Stack>
            <Button
              type="submit"
              variant="contained"
              sx={{ width: '100%', marginTop: '25px' }}
            >
              Добавить выходные
            </Button>
          </LocalizationProvider>
        </form>
      </Stack>
      <TableContainer component={Paper} sx={{ marginTop: '25px' }}>
        <Table sx={{ maxWidth: '100%' }} aria-label="holidays table">
          <TableHead>
            <TableRow>
              <TableCell size={isDesktop ? 'medium' : 'small'}>
                Выходные
              </TableCell>
              <TableCell size={isDesktop ? 'medium' : 'small'}>
                Эстимейты
              </TableCell>
              <TableCell size={isDesktop ? 'medium' : 'small'}>
                Инициатор
              </TableCell>
              <TableCell size={isDesktop ? 'medium' : 'small'} />
            </TableRow>
          </TableHead>
          <TableBody>
            {holidays.map((holiday) => (
              <TableRow>
                <TableCell
                  size={isDesktop ? 'medium' : 'small'}
                  sx={{ width: '500px' }}
                >
                  {holiday.description} с{' '}
                  {moment(holiday.first_day).format(dateFormat)} по{' '}
                  {moment(holiday.last_day).format(dateFormat)}
                </TableCell>
                <TableCell size={isDesktop ? 'medium' : 'small'}>
                  {holiday.days_last * 8}:00
                </TableCell>
                <TableCell size={isDesktop ? 'medium' : 'small'}>
                  {holiday.initiator.full_name}
                </TableCell>
                <TableCell size={isDesktop ? 'medium' : 'small'}>
                  <Button
                    onClick={() => handleDeleteHoliday(holiday.id)}
                    sx={{ textTransform: 'none' }}
                  >
                    Удалить
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
