import { atom } from 'recoil';

export interface IAssignment {
  id: number;
  name: string;
  external_manager_id: number;
  external_link: string;
  estimated_time: string;
  tracked_time: string;
  is_closed: boolean;
  is_one_to_one: boolean;
  month_accepted: string;
  month_declined: string;
  month_tracked_sum: string;
  before_tracked_sum: string;
  manager?: {
    full_name: string;
  };
}

export const assignmentState = atom<IAssignment[]>({
  key: 'Assignment',
  default: [],
});
