import { CircularProgress } from '@mui/material';
import React from 'react';

export default function LoadingLayout(props: { isLoading: boolean }) {
  const { isLoading } = props;
  return (
    <div className={isLoading ? 'loading--active' : 'loading'}>
      <CircularProgress />
    </div>
  );
}
