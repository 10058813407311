import React from 'react';
import userState from 'Recoil/Atoms/User';
import { useRecoilValue } from 'recoil';
import UserEffect from './UserEffect';

export default function AtomEffects() {
  const { logged } = useRecoilValue(userState);

  if (logged) {
    return <UserEffect />;
  }
  return null;
}
