import { nanoid } from 'nanoid';
import React, { useRef } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

interface ICheckbox {
  name: string;
  defaultChecked?: boolean;
  required?: boolean;
  label?: string;
}

export default function FormCheckbox({
  defaultChecked,
  name,
  label,
  required,
}: ICheckbox) {
  const { control } = useFormContext();

  return (
    <Controller
      rules={{ required }}
      control={control}
      name={name}
      render={() => (
        <FormGroup>
          <FormControlLabel
            control={<Checkbox defaultChecked={defaultChecked} />}
            label={label}
          />
        </FormGroup>
      )}
    />
  );
}
