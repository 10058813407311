import { atom } from 'recoil';

export interface IEstimate {
  id: number;
  name: string;
  old_value: string;
  new_value: string;
  tracked_time: string;
  assignment: {
    name: string;
    external_link: string;
  };
  change_timestamp: string;
  assignment_is_closed: boolean;
  initiator: {
    full_name: string;
  };
  user: {
    full_name: string;
  };
}

export const estimatesState = atom<IEstimate[]>({
  key: 'Estimates',
  default: [],
});
