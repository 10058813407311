import React, { useState, useMemo, useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import Button from '@mui/material/Button';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import useResize from 'Hooks/useResize';
import { reportsState } from 'Recoil/Atoms/Reports';
import userState from 'Recoil/Atoms/User';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import Breadcrumbs from 'Components/Breadcrumbs';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import MonthPicker from 'Components/MonthPicker';
import ROUTES from 'Dictionary/routes';
import { ADMINISTRATORS } from 'Dictionary/roles';
import { blue } from '@mui/material/colors';
import { Stack, Typography } from '@mui/material';
import { CalendarMonth } from '@mui/icons-material';
import Api from 'Api';
import useAPIError from 'Hooks/useAPIError';
import LoadingLayout from 'Components/Layout';

export default function Reports() {
  const reports = useRecoilValue(reportsState);
  const setReports = useSetRecoilState(reportsState);
  const { isDesktop } = useResize();
  const { id, role } = useRecoilValue(userState);
  const navigate = useNavigate();
  const [month, setMonth] = useState(new Date().getMonth() + 1);
  const [year, setYear] = useState(new Date().getFullYear());
  const { handleAPIError } = useAPIError();
  const [loading, setLoading] = useState<boolean>(false);

  const styles = useMemo(() => {
    return {
      tableCell: { width: isDesktop ? 50 : 25, padding: isDesktop ? 16 : 10 },
      tableCellWide: {
        width: isDesktop ? 250 : 100,
        padding: isDesktop ? 16 : 10,
      },
      tableCellMedium: {
        width: isDesktop ? 150 : 50,
        padding: isDesktop ? 16 : 10,
      },
    };
  }, [isDesktop]);

  useEffect(() => {
    async function fetchReports() {
      try {
        if (role && ADMINISTRATORS.includes(role)) {
          const { results } = await Api.get(Api.routes.api.reports(), {
            month: month.toString() || new Date().getMonth() + 1,
            year: year.toString() || new Date().getFullYear(),
          });
          setReports(results);
          setLoading(false);
        } else {
          const { results } = await Api.get(Api.routes.api.reports(), {
            account_id: id,
            month: month.toString() || new Date().getMonth() + 1,
            year: year.toString() || new Date().getFullYear(),
          });
          setReports(results);
        }
      } catch (error: any) {
        handleAPIError(error);
      }
    }
    fetchReports();
  }, [month]);

  const refreshCollab = async () => {
    setLoading(true);
    async function refreshReports() {
      try {
        await Api.getRefresh(
          Api.routes.importApi.report(year.toString(), month.toString()),
          {}
        );
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        handleAPIError(error);
        setLoading(false);
      }
    }
    async function fetchReports() {
      try {
        const { results } = await Api.get(Api.routes.api.reports(), {
          month: month.toString() || new Date().getMonth() + 1,
          year: year.toString() || new Date().getFullYear(),
        });
        setReports(results);
        setLoading(false);
      } catch (error: any) {
        handleAPIError(error);
        setLoading(false);
      }
    }

    refreshReports();
    fetchReports();
  };

  return (
    <>
      <LoadingLayout isLoading={loading} />
      <Breadcrumbs />
      <Typography variant="h4" sx={{ margin: '25px 0' }}>
        Отчеты
      </Typography>
      <MonthPicker
        month={month}
        monthChange={setMonth}
        year={year}
        yearChange={setYear}
      />
      <Stack direction="column" sx={{ width: 'fit-content', margin: '25px 0' }}>
        <Button
          variant="outlined"
          size="small"
          onClick={refreshCollab}
          sx={{ marginBottom: '10px' }}
        >
          Обновить данные с Active Collab
        </Button>
        {ADMINISTRATORS.includes(role || 'user') && (
          <Button
            variant="outlined"
            size="small"
            component={RouterLink}
            to={ROUTES.holidays()}
            sx={{ alignSelf: 'flex-start' }}
          >
            <CalendarMonth sx={{ marginRight: '8px' }} />
            Добавить выходные
          </Button>
        )}
      </Stack>
      <TableContainer component={Paper}>
        <Table sx={{ maxWidth: '100%' }} aria-label="projects table">
          <TableHead>
            <TableRow>
              <TableCell size={isDesktop ? 'medium' : 'small'}>
                Имя сотрудника
              </TableCell>
              <TableCell size={isDesktop ? 'medium' : 'small'} align="right">
                Списано времени за месяц
              </TableCell>
              <TableCell size={isDesktop ? 'medium' : 'small'} align="right">
                В зачет
              </TableCell>
              <TableCell size={isDesktop ? 'medium' : 'small'} align="right">
                Не зачтено
              </TableCell>
              <TableCell size={isDesktop ? 'medium' : 'small'} align="right">
                К оплате
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {reports.map((row) => (
              <TableRow
                key={row?.id || Math.random()}
                sx={{
                  textDecoration: 'none',
                }}
              >
                <TableCell
                  style={styles.tableCellMedium}
                  component="th"
                  scope="row"
                >
                  {row?.account.full_name}
                </TableCell>
                <TableCell
                  onClick={() =>
                    navigate(ROUTES.report(row?.id.toString()), {
                      state: {
                        id: row?.account.id,
                        reportMonth: month,
                        reportYear: year,
                        name: row?.account.full_name,
                      },
                    })
                  }
                  sx={{
                    color: blue[500],
                    '&:hover': {
                      cursor: 'pointer',
                      color: blue[300],
                    },
                  }}
                  style={styles.tableCell}
                  align="right"
                >
                  {row?.total}
                </TableCell>
                <TableCell
                  onClick={() =>
                    navigate(ROUTES.estimateReport(row?.id.toString()), {
                      state: {
                        id: row?.account.id,
                        reportMonth: month,
                        reportYear: year,
                        name: row?.account.full_name,
                      },
                    })
                  }
                  sx={{
                    color: blue[500],
                    '&:hover': {
                      cursor: 'pointer',
                      color: blue[300],
                    },
                  }}
                  style={styles.tableCell}
                  align="right"
                >
                  {row?.accepted}
                </TableCell>
                <TableCell
                  onClick={() =>
                    navigate(ROUTES.failedReport(row?.id.toString()), {
                      state: {
                        id: row?.account.id,
                        reportMonth: month,
                        reportYear: year,
                        name: row?.account.full_name,
                      },
                    })
                  }
                  sx={{
                    color: blue[500],
                    '&:hover': {
                      cursor: 'pointer',
                      color: blue[300],
                    },
                  }}
                  style={styles.tableCell}
                  align="right"
                >
                  {row?.declined}
                </TableCell>
                <TableCell style={styles.tableCell} align="right">
                  {row?.payment_sum || ''}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Typography sx={{ marginTop: '50px' }}>Всего {reports.length}</Typography>
    </>
  );
}
