import React, { useEffect } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Paper,
  Typography,
} from '@mui/material';
import { unitsState } from 'Recoil/Atoms/Units';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import useResize from 'Hooks/useResize';
import { unitState } from 'Recoil/Atoms/Unit';
import Breadcrumbs from 'Components/Breadcrumbs';
import useAPIError from 'Hooks/useAPIError';
import Api from 'Api';
import ModalUnit from './ModalUnit';

export default function Units() {
  const units = useRecoilValue(unitsState);
  const setUnitState = useSetRecoilState(unitState);
  const setUnitsState = useSetRecoilState(unitsState);
  const { isDesktop } = useResize();
  const { handleAPIError } = useAPIError();

  useEffect(() => {
    async function fetchUnits() {
      try {
        const { results } = await Api.get(Api.routes.api.units());
        setUnitsState(results);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        handleAPIError(error);
      }
    }

    fetchUnits();
  }, []);

  return (
    <>
      <Breadcrumbs />
      <Typography variant="h4" sx={{ margin: '25px 0' }}>
        Подразделения
      </Typography>
      <ModalUnit />
      <Button
        variant="contained"
        size="small"
        sx={{ margin: '20px 0' }}
        onClick={() =>
          setUnitState({
            status: true,
            dialog: 'add',
            name: 'Новое подразделение',
          })
        }
      >
        Добавить подразделение
      </Button>
      <TableContainer component={Paper}>
        <Table sx={{ maxWidth: '100%' }} aria-label="units table">
          <TableHead>
            <TableRow>
              <TableCell size={isDesktop ? 'medium' : 'small'}>
                Название
              </TableCell>
              <TableCell size={isDesktop ? 'medium' : 'small'} />
            </TableRow>
          </TableHead>
          <TableBody>
            {units.map((row) => (
              <TableRow key={row.id}>
                <TableCell
                  size={isDesktop ? 'medium' : 'small'}
                  component="th"
                  scope="row"
                >
                  <Button
                    onClick={() =>
                      setUnitState({
                        status: true,
                        name: row.name,
                        id: row.id,
                        dialog: 'save',
                      })
                    }
                    sx={{
                      textTransform: 'none',
                      textAlign: 'left',
                      justifyContent: 'start',
                    }}
                  >
                    {row.name}
                  </Button>
                </TableCell>
                <TableCell size={isDesktop ? 'medium' : 'small'} align="right">
                  <Button
                    onClick={() =>
                      setUnitState({
                        status: true,
                        name: row.name,
                        id: row.id,
                        dialog: 'delete',
                      })
                    }
                    sx={{ textTransform: 'none' }}
                  >
                    Удалить
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Typography sx={{ marginTop: '50px' }}>Всего {units.length}</Typography>
    </>
  );
}
