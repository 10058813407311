import { atom } from 'recoil';

export interface IProject {
  id: number;
  name: string;
  external_manager_id: number;
  external_project_id: number;
  manager: {
    full_name: string;
  };
}

export const projectsState = atom<IProject[]>({
  key: 'Projects',
  default: [],
});
