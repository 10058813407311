import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from '@mui/material';
import { unitsState } from 'Recoil/Atoms/Units';
import { unitState } from 'Recoil/Atoms/Unit';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import Api from 'Api';
import useAPIError from 'Hooks/useAPIError';
import useResize from 'Hooks/useResize';

export default function ModalUnit() {
  const units = useRecoilValue(unitsState);
  const setUnitsState = useSetRecoilState(unitsState);
  const unit = useRecoilValue(unitState);
  const setUnitState = useSetRecoilState(unitState);
  const [newName, setNewName] = useState(unit.name);
  const { handleAPIError } = useAPIError();
  const { isDesktop } = useResize();

  async function handleDeleteUnit(id?: number) {
    try {
      await Api.deleteRequest(Api.routes.api.unit(id?.toString()));
      setUnitsState(units.filter((elem) => elem.id !== id));
      setUnitState({ ...unit, status: false });
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      handleAPIError(error);
    }
  }

  async function handleChangeUnit(name: string, id?: number) {
    try {
      await Api.put(Api.routes.api.unit(id?.toString()), { name });
      setUnitsState(
        units.map((elem) => (elem.id === id ? { ...elem, name } : elem))
      );
      setUnitState({ ...unit, status: false });
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      handleAPIError(error);
    }
  }

  async function handleAddUnit(name: string) {
    try {
      const { results } = await Api.post(Api.routes.api.units(), { name });
      setUnitsState((currState) => [
        ...currState,
        { id: results.id, name: results.name },
      ]);
      setUnitState({ ...unit, status: false });
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      handleAPIError(error);
    }
  }

  return (
    <Dialog
      open={unit.status}
      onClose={() => setUnitState({ ...unit, status: false })}
    >
      <DialogTitle id="dialog-title">
        {unit.dialog === 'delete' ? 'Предупреждение' : unit.name}
      </DialogTitle>
      <DialogContent>
        {unit.dialog === 'delete' ? (
          <DialogContentText id="alert-dialog-description">
            Вы уверены что хотите удалить подразделение &quot;{unit.name}&quot;?
          </DialogContentText>
        ) : (
          <TextField
            fullWidth
            variant="outlined"
            label={
              unit.dialog === 'add'
                ? 'Название поздразделения'
                : 'Новое название подразделения'
            }
            defaultValue={unit.dialog === 'add' ? '' : unit.name}
            onChange={(event) => setNewName(event.target.value)}
            sx={{
              marginTop: '5px',
              minWidth: isDesktop ? '300px' : '',
            }}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setUnitState({ ...unit, status: false })}>
          Отмена
        </Button>
        {unit.dialog === 'delete' ? (
          <Button onClick={() => handleDeleteUnit(unit?.id)}>Ок</Button>
        ) : (
          <Button
            onClick={
              unit.dialog === 'add'
                ? () => handleAddUnit(newName)
                : () => handleChangeUnit(newName, unit?.id)
            }
          >
            Сохранить
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
