/* eslint-disable camelcase */
import React, { useEffect } from 'react';
import { Paper, Typography, Stack, Button, Modal, Box } from '@mui/material';
import { useRecoilState } from 'recoil';
import userState from 'Recoil/Atoms/User';
import UserEffect from 'Recoil/Effects/UserEffect';
import useResize from 'Hooks/useResize';
import Breadcrumbs from 'Components/Breadcrumbs';
import { grey } from '@mui/material/colors';
import { useForm, FormProvider } from 'react-hook-form';
import Field from 'Components/Form/Field';
import FormCheckbox from 'Components/Form/Checkbox';
import useAPIError from 'Hooks/useAPIError';
import Api from 'Api';
import setDefaultValues from './helper';

export default function PersonalAccountUpdate() {
  const [user, setUser] = useRecoilState(userState);
  const [open, setOpen] = React.useState(false);
  const handleClose = () => setOpen(false);
  const { isDesktop } = useResize();
  const { handleAPIError } = useAPIError();
  const methods = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    defaultValues: setDefaultValues(user),
  });

  async function onSubmit(values: any) {
    try {
      if (user?.id) {
        const { success, results } = await Api.put(
          Api.routes.api.updateSettings(user?.id.toString()),
          { ...values }
        );
        if (success) {
          setOpen(true);
          setUser((prev) => ({ ...prev, ...results }));
        }
      }
    } catch (error: any) {
      handleAPIError(error);
    }
  }

  useEffect(() => {
    if (user) {
      methods.reset(setDefaultValues(user));
    }
  }, [user]);

  return (
    <>
      <Breadcrumbs lastName="Редактирование профиля" />
      <UserEffect />
      <Typography variant="h4" sx={{ margin: '25px 0' }}>
        Личный кабинет
      </Typography>
      <Paper
        sx={{
          color: grey[700],
          marginX: isDesktop ? '30%' : '',
          marginTop: '20px',
          padding: '20px',
        }}
      >
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <Stack direction="column" spacing={2}>
              <Field name="first_name" label="Имя" />
              <Field name="last_name" label="Фамилия" />
              <Field name="email" label="Корпоративная почта" />
              <FormCheckbox
                name="is_email_receiver"
                label="Получать письма на почту"
              />
              <Stack direction="row" justifyContent="flex-end">
                <Button
                  sx={{
                    width: 'fit-content',
                    height: 'fit-content',
                    padding: '5px',
                    marginLeft: '20px',
                  }}
                  type="submit"
                >
                  Сохранить
                </Button>
              </Stack>
            </Stack>
          </form>
        </FormProvider>
      </Paper>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: 'absolute' as const,
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Успешно
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Изменения сохранены
          </Typography>
          <Button
            sx={{
              width: 'fit-content',
              height: 'fit-content',
              padding: '5px',
              marginLeft: 'auto',
            }}
            onClick={() => handleClose()}
          >
            ОК
          </Button>
        </Box>
      </Modal>
    </>
  );
}
