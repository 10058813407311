import { atom } from 'recoil';

interface IInitiator {
  full_name: string;
}
export interface IHolidays {
  id: number;
  first_day: string;
  last_day: string;
  description: string;
  days_last: number;
  initiator: IInitiator;
}

export const holidaysState = atom<IHolidays[]>({
  key: 'Holidays',
  default: [],
});
