const BASE_URL = process.env.REACT_APP_API_BASE;
const CLIENT_URL = process.env.REACT_APP_CLIENT_API;
const IMPORT_URL = process.env.REACT_APP_IMPORT_API;

const api = {
  projects: () => `${BASE_URL}/project`,
  auth: () => `${BASE_URL}/signin/google/auth`,
  me: () => `${BASE_URL}/me`,
  signOut: () => `${CLIENT_URL}/signout`,
  budget: () => `${BASE_URL}/budget`,
  units: () => `${BASE_URL}/unit`,
  createBudget: () => `${BASE_URL}/budget`,
  estimate: () => `${BASE_URL}/estimate-change`,
  unit: (id = ':id') => `${BASE_URL}/unit/${id}`,
  users: () => `${BASE_URL}/account`,
  updateSettings: (id = ':id') => `${BASE_URL}/account/${id}`,
  reports: () => `${BASE_URL}/user-month-value`,
  report: () => `${BASE_URL}/timerecord`,
  assignment: () => `${BASE_URL}/assignment`,
  budgetReport: () => `${BASE_URL}/budget/by-project`,
  holidays: () => `${BASE_URL}/holiday`,
  holiday: (id = ':id') => `${BASE_URL}/holiday/${id}`,
  dictionary: () => `${BASE_URL}/dictionary`,
  vacation: () => `${BASE_URL}/vacation`,
  payments: () => `${BASE_URL}/payment`,
  payment: (id = ':id') => `${BASE_URL}/payment/${id}`,
  timetable: () => `${BASE_URL}/timetable`,
  loginAsUser: (id = ':id') => `${BASE_URL}/account/${id}/login`,
};

const importApi = {
  users: () => `${IMPORT_URL}/account`,
  project: () => `${IMPORT_URL}/project`,
  report: (year = ':year', month = ':month') =>
    `${IMPORT_URL}/report/${year}/${month}`,
};

export default { api, importApi };
