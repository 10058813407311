import React from 'react';
import { Box, Stack, Typography, Button } from '@mui/material';
import MONTHS from 'Dictionary/months';

interface IProps {
  month: number;
  monthChange: (value: number) => void;
  year: number;
  yearChange: (value: number) => void;
}

export default function MonthPicker({
  month,
  monthChange,
  year,
  yearChange,
}: IProps) {
  const increaseMonth = () => {
    if (month === 12) {
      monthChange(1);
      yearChange(year + 1);
    } else {
      monthChange(month + 1);
    }
  };

  const decreaseMonth = () => {
    if (month === 1) {
      monthChange(12);
      yearChange(year - 1);
    } else {
      monthChange(month - 1);
    }
  };

  return (
    <Box>
      <Stack direction="row" sx={{ margin: '20px 0' }}>
        <Button sx={{ width: 100 }} variant="text" onClick={decreaseMonth}>
          {MONTHS[month <= 1 ? month + 12 - 1 : month - 1]}
        </Button>
        <Typography
          sx={{
            textTransform: 'uppercase',
            fontSize: 24,
            width: 150,
            textAlign: 'center',
          }}
        >
          {MONTHS[month]}
        </Typography>
        <Button
          sx={{ width: 100 }}
          variant="text"
          disabled={
            month > new Date().getMonth() && year === new Date().getFullYear()
          }
          onClick={increaseMonth}
        >
          {MONTHS[month >= 12 ? month - 12 + 1 : month + 1]}
        </Button>
      </Stack>
    </Box>
  );
}
