import React from 'react';
import { ListItem, Button } from '@mui/material';
import { grey } from '@mui/material/colors';
import { Link as RouterLink } from 'react-router-dom';

interface IProps {
  href?: string;
  text: string;
  isMobile?: boolean;
  fontSize: string;
  onClick?: () => void;
}

export default function MenuButton({
  href,
  text,
  isMobile,
  fontSize,
  onClick,
}: IProps) {
  return isMobile ? (
    <ListItem>
      <Button
        variant="text"
        component={href ? RouterLink : Button}
        to={href}
        onClick={onClick}
        size="small"
        sx={(theme) => ({
          color: grey[600],
          fontSize,
          '&:hover': {
            color: grey[800],
          },
          [theme.breakpoints.down('sm')]: {
            marginLeft: '15px',
          },
        })}
      >
        {text}
      </Button>
    </ListItem>
  ) : (
    <Button
      component={href ? RouterLink : Button}
      to={href}
      onClick={onClick}
      variant="text"
      size="small"
      sx={(theme) => ({
        color: grey[600],
        fontSize,
        '&:hover': {
          color: grey[800],
        },
        [theme.breakpoints.down('sm')]: {
          marginLeft: '15px',
        },
      })}
    >
      {text}
    </Button>
  );
}
