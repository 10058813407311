import * as React from 'react';
import { useEffect, useState } from 'react';
import Api from 'Api';
import { useLocation } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import useResize from 'Hooks/useResize';
import { unitsState } from 'Recoil/Atoms/Units';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import { Typography } from '@mui/material';
import Breadcrumbs from 'Components/Breadcrumbs';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import useAPIError from 'Hooks/useAPIError';

interface IBudget {
  id: number;
  cost: number;
  cost_per_hour: number;
  unit?: {
    id: number;
    name: string;
  };
}

interface IFormInput {
  cost: string;
  cost_per_hour: string;
}

export default function Project() {
  const { state } = useLocation();
  const { name, id } = state;
  const units = useRecoilValue(unitsState);
  const setUnitsState = useSetRecoilState(unitsState);
  const [unitId, setUnitId] = useState('');
  const [budget, setBudget] = useState<null | IBudget[]>(null);
  const { isDesktop } = useResize();
  const { control, handleSubmit, reset } = useForm<IFormInput>();
  const { handleAPIError } = useAPIError();

  async function fetchBudgets() {
    try {
      const { results } = await Api.get(Api.routes.api.budget(), {
        project_id: id,
      });
      setBudget(results);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      handleAPIError(error);
    }
  }

  async function fetchUnits() {
    try {
      const { results } = await Api.get(Api.routes.api.units());
      setUnitsState(results);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      handleAPIError(error);
    }
  }

  const onSubmit: SubmitHandler<IFormInput> = (data: IFormInput) => {
    async function createBudget() {
      try {
        await Api.post(Api.routes.api.budget(), {
          project_id: id,
          unit_id: unitId,
          ...data,
        });
        fetchBudgets();
        reset();
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        handleAPIError(error);
      }
    }
    createBudget();
  };

  useEffect(() => {
    fetchBudgets();
    fetchUnits();
  }, [id]);

  const handleChange = (event: SelectChangeEvent) => {
    setUnitId(event.target.value as string);
  };

  const styles = React.useMemo(() => {
    return {
      tableCell: { width: isDesktop ? 50 : 5 },
      tableCellWide: { width: isDesktop ? 250 : 50 },
      tableCellHead: { padding: 10 },
      form: {
        width: isDesktop ? '75%' : 'calc(100% - 20px)',
        margin: isDesktop ? '20px auto' : '10px auto',
        padding: isDesktop ? 20 : 10,
      },
    };
  }, [isDesktop]);

  return (
    <>
      <Breadcrumbs lastName={name} />
      <Typography variant="h4" sx={{ margin: '25px 0' }}>
        {name}
      </Typography>
      <TableContainer component={Paper}>
        <Table sx={{ maxWidth: '100%' }} aria-label="budget table">
          <TableHead>
            <TableRow>
              <TableCell
                style={styles.tableCellHead}
                size={isDesktop ? 'medium' : 'small'}
              >
                #
              </TableCell>
              <TableCell
                style={styles.tableCellHead}
                size={isDesktop ? 'medium' : 'small'}
                align="left"
              >
                Название
              </TableCell>
              <TableCell
                style={styles.tableCellHead}
                size={isDesktop ? 'medium' : 'small'}
                align="right"
              >
                Стоимость
              </TableCell>
              <TableCell
                style={styles.tableCellHead}
                size={isDesktop ? 'medium' : 'small'}
                align="right"
              >
                {isDesktop ? 'Стоимость в час' : 'В час'}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {budget?.map((row: IBudget) => (
              <TableRow
                key={row.id}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell style={styles.tableCell} component="th" scope="row">
                  {row.id}
                </TableCell>
                <TableCell align="left">{row.unit?.name || ''}</TableCell>
                <TableCell style={styles.tableCellWide} align="right">
                  {row.cost}
                </TableCell>
                <TableCell style={styles.tableCellWide} align="right">
                  {row.cost_per_hour}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Paper style={styles.form}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={2}>
            <Controller
              name="cost"
              control={control}
              defaultValue=""
              render={({ field }) => <TextField {...field} label="Стоимость" />}
            />
            <Controller
              name="cost_per_hour"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField {...field} label="Стоимость в час" />
              )}
            />
            <Select value={unitId} onChange={handleChange}>
              {units?.map((unit) => {
                return (
                  <MenuItem key={unit.id} value={unit.id.toString()}>
                    {unit.name}
                  </MenuItem>
                );
              })}
            </Select>
            <Button type="submit" variant="outlined" size="large">
              Создать
            </Button>
          </Stack>
        </form>
      </Paper>
    </>
  );
}
