import { useSetRecoilState } from 'recoil';
import { useEffect } from 'react';
import Api from 'Api';
import useAPIError from 'Hooks/useAPIError';

import { reportState, IReport } from 'Recoil/Atoms/Report';

interface IProps {
  month?: number;
  year?: number;
  id?: number;
}

export function ReportEffect({ month, year, id }: IProps) {
  const setReportState = useSetRecoilState(reportState);
  const { handleAPIError } = useAPIError();

  useEffect(() => {
    async function fetchReport() {
      try {
        const { results } = await Api.get(Api.routes.api.report(), {
          account_id: id,
          report_month: month || new Date().getMonth() + 1,
          report_year: year || new Date().getFullYear(),
        });
        results.sort((a: IReport, b: IReport) =>
          a.assignment.name.localeCompare(b.assignment.name)
        );
        setReportState(results);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        handleAPIError(error);
      }
    }

    fetchReport();
  }, [month]);

  return null;
}
