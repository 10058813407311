import { atom } from 'recoil';

export interface IUnits {
  id: number;
  name: string;
}

export const unitsState = atom<IUnits[]>({
  key: 'Units',
  default: [],
});
