import Api from 'Api';
import { useNavigate } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import userState from 'Recoil/Atoms/User';
import Utilities from 'Utilities';
import secureLocalStorage from 'react-secure-storage';
import useAPIError from './useAPIError';
import ROUTES from '../Dictionary/routes';

export default function useUserActions() {
  const navigate = useNavigate();
  const setUser = useSetRecoilState(userState);
  const { handleAPIError } = useAPIError();

  const fetchUser = async () => {
    try {
      const { results } = await Api.get(Api.routes.api.me());
      setUser({
        logged: true,
        ...results,
      });
      secureLocalStorage.setItem('gain_role_token', results.role);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      setUser({
        logged: false,
      });
      secureLocalStorage.removeItem('gain_role_token');
      Utilities.apiTokenStorage.remove();
      handleAPIError(error);
    }
  };

  const signOut = async () => {
    // await Api.post(Api.routes.api.signOut(), {});
    Utilities.apiTokenStorage.remove();
    setUser({
      logged: false,
    });
    secureLocalStorage.removeItem('gain_role_token');
    navigate(ROUTES.auth());
  };

  return {
    fetchUser,
    signOut,
  };
}
