import React from 'react';
import {
  Breadcrumbs as MUIBreadcrumbs,
  Button,
  Link,
  Typography,
} from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import ROUTES from 'Dictionary/routes';
import CRUMBS from 'Dictionary/crumbs';

interface IBreadcrumb {
  lastName?: string;
}

export default function Breadcrumbs({ lastName }: IBreadcrumb) {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const dictionary = CRUMBS;

  const pathnames = pathname.split('/').filter((path) => path);

  const compareDictionary = (name: string) => {
    return Object.keys(dictionary).includes(name)
      ? dictionary[name as keyof typeof dictionary]
      : name;
  };

  return (
    <MUIBreadcrumbs sx={{ color: 'rgb(204, 204, 204)', marginTop: '30px' }}>
      <Link href={ROUTES.reports()} underline="hover" sx={{ fontSize: 14 }}>
        Главная
      </Link>
      {pathnames.map((name, index) => {
        const routeTo = `/${pathnames.slice(0, index + 1).join('/')}`;
        const isLast = index === pathnames.length - 1;

        return isLast ? (
          <Typography key={name} sx={{ fontSize: 14, color: '#777' }}>
            {lastName || compareDictionary(name)}
          </Typography>
        ) : (
          <Button
            key={name}
            onClick={() => navigate(routeTo)}
            sx={{
              textTransform: 'none',
              padding: '0',
              minWidth: 0,
              '&:hover': {
                backgroundColor: 'transparent',
                textDecoration: 'underline',
              },
            }}
          >
            {compareDictionary(name)}
          </Button>
        );
      })}
    </MUIBreadcrumbs>
  );
}
